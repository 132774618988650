import React, { useContext } from 'react';
import Image from '@jetshop/ui/Image';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Logo = props => {
  const channelTwo = useContext(ChannelContext)?.selectedChannel?.id === 2;
  let src = channelTwo
    ? '/pub_images/original/logo-jakt.png'
    : '/pub_images/original/logo_rod_tm-2x.png';
  if (props.mobile)
    src = channelTwo
      ? '/pub_images/original/logo-jakt-mobil.png'
      : '/pub_images/original/mobile-logo-redX2.png';

  if (props.mobile) {
    return <Image src={src} sizes={['200']} aspect={'3:1'} crop={false} />;
  } else {
    return <Image src={src} aspect={'1:1'} />;
  }
};

export default Logo;
