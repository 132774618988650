import React, { Component, Fragment } from 'react';
import SiteContent from '../../Util/SiteContent';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theme';
import Image from '@jetshop/ui/Image';
import NewsletterField from '../../Newsletter/NewsletterField';

export const LowerContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;

  ${theme.below.md} {
    flex-direction: column;
  }
  > div {
    flex-grow: 1;
    padding: 2rem 0rem;
    ${theme.only.md} {
      width: 100%;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 1rem;
    }
    > h3 {
      margin-bottom: 2rem;
      line-height: 1.5em;
      font-size: 1rem;
      text-align: left;
      text-transform: uppercase;
    }
    * {
    }
    li,
    p {
      padding: 0;
      line-height: 1.6em;
    }
    img {
      max-width: 250px;
      max-height: 100px;
    }
  }

  .icon-title {
    margin-top: 2rem;
  }
  .icon-list {
    display: flex;
    justify-content: center;
    li {
      padding: 0 0.5rem;
    }
  }
`;

const ImageWrapper = styled('div')`
  height: 100px;
`;

class FooterLowerContent extends Component {
  render() {
    return (
      <SiteContent names={['Footer1', 'Footer2', 'Footer3', 'Footer4']}>
        {(items, renderLink) => {
          return (
            <Fragment>
              <LowerContentWrapper className="footer-lower-content">
                {items.map((item, index) => {
                  const { content } = item;
                  if (content.image != null) {
                    return (
                      <div key={index}>
                        {content.title && <h3>{renderLink(content.title)}</h3>}
                        {content.list && (
                          <ul>
                            {content.list.map((listItem, index) => (
                              <li key={index}>{renderLink(listItem)}</li>
                            ))}
                          </ul>
                        )}
                        <ImageWrapper>
                          <Image
                            src="/pub_images/original/arets-logistik-footer-new-2x.png"
                            aspect="4:3"
                          />
                        </ImageWrapper>
                        {content.newsletter && <NewsletterField />}
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      {content.title && <h3>{renderLink(content.title)}</h3>}
                      {content.list && (
                        <ul>
                          {content.list.map((listItem, index) =>
                            listItem[1].includes('mailto:') ? (
                              <li key={index}>
                                <a href={listItem[1]}>{listItem[0]}</a>
                              </li>
                            ) : (
                              <li key={index}>{renderLink(listItem)}</li>
                            )
                          )}
                        </ul>
                      )}
                      {content.newsletter && <NewsletterField />}
                    </div>
                  );
                })}
              </LowerContentWrapper>
            </Fragment>
          );
        }}
      </SiteContent>
    );
  }
}

export default FooterLowerContent;
