import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

//STYLED COMPONENTS
const StandardVariantWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
`;
const TextWrapper = styled('p')`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: -0.4px;
  > span:not(:last-child) {
    &:after {
      content: '/';
      margin: 0 2px;
    }
  }
`;

export const StandardVariant = ({ variantHandler, product }) => {
  const { validateSelection } = variantHandler;

  //SHOW ON SIZE ONLY
  const optionArray = product.variants.options.filter(
    x => x.name && x.name.includes('Størrelse')
  );
  const option = optionArray.length && optionArray[0];

  let availableValues = [];
  if (option) {
    option.values.map(value => {
      const validation = validateSelection(value, option);
      if (validation === 'valid') {
        availableValues.push(value);
      }
      return false;
    });
  }

  if (option)
    return (
      <StandardVariantWrapper>
        {availableValues.length === 0 ? (
          <TextWrapper>
            {product.variants?.values
              ? product.stockStatus.text
              : t('Available sizes')}
          </TextWrapper>
        ) : (
          <div>
            <TextWrapper>
              {availableValues.map((x, index) => (
                <span key={index}>{x}</span>
              ))}
            </TextWrapper>
          </div>
        )}
      </StandardVariantWrapper>
    );
  return null;
};
