import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Price } from '../Price';
import { theme } from '../Theming/Theme';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { PercentPrice } from '../ProductPage/PercentPrice';
import { PriceFormatter } from '../ui/PriceFormatter';
import LipscoreSmallRating from '../ProductPage/Lipscore/LipscoreSmallRating';
import { VariantHandler } from './VariantHandler/VariantHandler';
import { useLazyQuery } from 'react-apollo';
import ProductGridVariantsQuery from './ProductGridVariantsQuery.gql';

export const ProductCardInner = styled('div')`
  border: 1px solid ${theme.colors.borderLight};
  background-color: white;
  position: relative;
  padding: 0.5rem;
`;

export const Details = styled('div')`
  position: relative;
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

export const ImageWrapper = styled('div')`
  position: relative;
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.8rem;
  img {
    width: 110px;
    height: 110px;
    ${theme.below.md} {
      width: 90px;
      height: 90px;
    }
  }
`;

export const Name = styled('h3')`
  font-size: 1rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1;
  font-weight: bold;
  overflow: hidden;
  text-transform: none;
  font-family: ${theme.font.families.body};
  height: 41px;
  margin-top: 5px;
  ${theme.below.sm} {
    height: 32px;
    font-size: 0.9rem;
    letter-spacing: -0.4px;
  }
`;
export const SubName = styled('p')`
  font-size: 14px;
  white-space: nowrap;
  font-family: ${theme.font.families.body};
  text-overflow: ellipsis;
  margin: 0 0 0.7rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.1;
  overflow: hidden;
  height: 14px;
  ${theme.below.md} {
    font-size: 0.9rem;
    height: 12px;
  }
  ${theme.below.sm} {
    font-size: 0.8rem;
  }
`;
export const ListPrice = styled(Price)`
  font-family: ${theme.font.families.heavy};
  font-size: 1.1rem;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  flex-direction: column;
  ${theme.below.md} {
    font-size: 1.2rem;
  }
  .new-price {
    color: ${theme.colors.discount};
    margin-bottom: 0.1rem;
  }
  .old-price {
    font-weight: ${theme.font.weights.normal};
    font-size: 1rem;
    ${theme.below.md} {
      font-size: 0.9rem;
    }
  }
`;
const ProductHeader = styled('header')``;

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }
`;

const LipScoreWrapper = styled('span')`
  margin: 8px auto 8px;
  text-align: center;
  text-align: -webkit-center;
  min-height: 24px;
`;

const PercentPriceWrapper = styled('div')`
  position: absolute;
`;

export const PriceWrapper = styled('div')`
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  font-size: 1.5rem;
  p {
    font-size: 1rem;
    line-height: 13px;
    padding-left: 2px;
    white-space: nowrap;
  }
  h4 {
    font-size: 1.5rem;
  }
`;

const VariantWrapper = styled('div')`
  height: 47px;
  margin-top: 11px;
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  displayColorCircles,
  rating,
  combineSecondTooltip,
  categoryPath,
  ...linkProps
}) => {
  const hasImages = product.images && product.images.length > 0;
  const variantHandler = useProductVariants(
    product.articleNumber ? product : undefined
  );
  const price = useDynamicPrice(product, variantHandler.selectedVariant);
  let images = product.images;
  if (
    variantHandler.selectedVariant &&
    variantHandler.selectedVariant.images.length
  )
    images = variantHandler.selectedVariant.images;
  return (
    <ProductCardInner className="product-card-inner">
      <ProductLink product={product} categoryPath={categoryPath} {...linkProps}>
        {hasImages ? (
          <ImageWrapper>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={images[0].alt}
              src={images[0].url}
            >
              <PercentPriceWrapper>
                <PercentPrice {...price} />
              </PercentPriceWrapper>
            </Image>
            <BadgeWrapper>
              <Badges badges={product.badges} />
            </BadgeWrapper>
          </ImageWrapper>
        ) : (
          <Image src={transparentDataImg} />
        )}
      </ProductLink>

      <Details className="product-card-detail">
        <LipScoreWrapper>
          {product.rating && <LipscoreSmallRating rating={product.rating} />}
        </LipScoreWrapper>
        <ProductHeader>
          <ProductLink product={product} {...linkProps}>
            <Name>{product.name}</Name>
          </ProductLink>
          <SubName className="sub-name">{product.subName || '\u00A0'}</SubName>
        </ProductHeader>
        {product.price == null ? (
          <ListPrice {...price} />
        ) : (
          <PriceWrapper>
            <div>
              {price.price !== undefined ? (
                <PriceFormatter
                  price={price.price}
                  previousPrice={price.previousPrice}
                  code={',-'}
                  culture={'nb-NO'}
                />
              ) : (
                <PriceFormatter
                  price={product.price}
                  previousPrice={product.previousPrice}
                  code={',-'}
                  culture={'nb-NO'}
                />
              )}
            </div>
          </PriceWrapper>
        )}
        {displayColorCircles && product.variants && (
          <VariantWrapper>
            {product.hasVariants && (
              <VariantHandler
                variantHandler={variantHandler}
                product={product}
                combineSecondTooltip={combineSecondTooltip}
              />
            )}
          </VariantWrapper>
        )}
      </Details>

      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 3, 1 / 4, 400],
  forwardRef,
  as = 'div',
  children,
  displayColorCircles,
  rating,
  combineSecondTooltip,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    displayColorCircles,
    rating,
    combineSecondTooltip,
    ...linkProps
  };

  const [fetch, { data }] = useLazyQuery(ProductGridVariantsQuery, {
    variables: { articleNumber: product.articleNumber }
  });
  const productInUse = { ...product, ...data?.product };

  return (
    <Tag
      className="product-card"
      css={[wrapperStyling, className]}
      data-testid="product"
      data-product-card
      ref={forwardRef}
      onClick={() => {
        fetch(true);
      }}
      onMouseEnter={() => {
        fetch(true);
      }}
    >
      <ProductCardComponent product={productInUse} {...props} />
    </Tag>
  );
}

export default ProductCard;
