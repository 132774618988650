import React from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from './CartQuery.gql';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import CartIcon from '../ui/icons/CartIcon';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  cursor: pointer;
  > div {
    //vertical-align: middle;
  }
  margin-left: 0.2rem;
  ${theme.below.lg} {
    margin-left: 0.5rem;
  }
`;

export const CartItems = styled('span')`
  display: block;
  position: absolute;
  right: -4px;
  top: 13px;
  width: 16px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  background: ${theme.topBar.background};
  color: ${theme.colors.white};
  //border: 1px solid #F1F1F1;
  border-radius: 50%;
  text-align: center;
  ${theme.above.xl} {
    top: 32px;
  }
`;

const DrawerTriggerWrapper = ({ itemsInCart }) => (
  <DrawerTrigger
    preventOverflow={true}
    id="cart-drawer"
    coverStyles={{ zIndex: 101 }}
  >
    {drawer => (
      <Button onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}>
        <CartIcon className="header-icon" />
        {parseInt(itemsInCart, 10) > 0 && <CartItems>{itemsInCart}</CartItems>}
      </Button>
    )}
  </DrawerTrigger>
);

const CartButton = () => (
  <CartProvider query={cartQuery}>
    {result => {
      // Set items in cart to the API result.
      // If the result is undefined, fall back to 0
      const itemsInCart = result?.data?.cart?.totalQuantity || 0;
      return <DrawerTriggerWrapper itemsInCart={itemsInCart} />;
    }}
  </CartProvider>
);

export default CartButton;
