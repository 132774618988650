import React from 'react';
import SiteContent from '../../../Util/SiteContent';

export const ExtraMenuLinks = ({ tag = 'li' }) => {
  const Element = tag;
  return (
    <SiteContent names={['MenuLinks']}>
      {(items, renderLink) => {
        const item = items[0];
        if (item && item.content.list && item.content.list.length)
          return item.content.list.map((listItem, index) => (
            <Element key={index + 20}>{renderLink(listItem)}</Element>
          ));
        return null;
      }}
    </SiteContent>
  );
};
