import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theme';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';

const VariantButtonWrapper = styled('div')`
  &.invalid {
    color: red;
    button {
      box-shadow: 0px 0px 5px red;
    }
  }
`;

export const VariantHeading = styled('h4')`
  margin-bottom: 0.3rem;
  text-transform: uppercase;
  font-size: 1rem;
`;

const StyledButtonWrapper = styled('div')`
  padding-bottom: 5px;
  margin-right: 15px;
  margin-bottom: 5px;
  &.selected {
    border-bottom: 3px solid black;
  }
`;

const VariantSizeButton = styled('button')`
  background: transparent;
  border: 1px solid ${theme.colors.font};
  cursor: pointer;
  text-align: center;
  width: max-content;
  padding: 1rem 10px;
  margin: 0 1% 2%;
  ${theme.above.sm} {
    margin: 0 1% 2%;
  }
  &.not-buyable {
    text-decoration: line-through;
  }
  &.selected {
    border-color: ${theme.colors.font};
  }
  &.disabled {
    border-bottom: 3px solid black;
    text-decoration: line-through;
    border: 1px solid #c2c2c2;
    color: rgba(0, 0, 0, 0.4);
  }
`;
const VariantSizeButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const VariantTitle = styled('div')`
  display: flex;
`;
const SizeGuideLink = styled('a')`
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  text-decoration: underline;
  > p {
    text-decoration: none;
  }
`;
const SizeGuideImageWrapper = styled('div')`
  width: 20px;
`;
const SizeGuideWrapper = styled('div')`
  display: flex;
`;

const VariantButton = ({
  option,
  variantHandler,
  OpenTab,
  product,
  showValidationMessage
}) => {
  const { validateSelection, selectValue } = variantHandler;
  const selectedValue = variantHandler.getSelectedValue(option);
  let hasSizeGuide;
  if (typeof document !== 'undefined') {
    hasSizeGuide = document.getElementById('tab-header-sizeguide');
    //Check if size guide tab exists in mobile
    if (hasSizeGuide == null) {
      hasSizeGuide = document.getElementById('drop-header-sizeguide');
    }
  }

  /*let missingSizeOption;
  if (missingOptions) {
    if (
      missingOptions.length === 1 &&
      missingOptions[0].name.includes('Størrelse')
    ) {
      missingSizeOption = true;
    }
  }*/

  //ONLY SHOW SIZE WHEN COLOR IS SELECTED
  let showSize = true;
  let colorExists = false;
  if (
    product.variants.options.length > 1 &&
    product.variants.options[0].name.includes('Farge')
  ) {
    showSize = variantHandler.getSelectedValue(product.variants.options[0])
      ? true
      : false;
    colorExists = true;
  }

  useEffect(() => {
    //SELECT FIRST IF NO ONE IS SELECTED
    if (!selectedValue && colorExists) {
      const values = option.values.filter(value => {
        return validateSelection(value, option) === 'valid';
      });
      if (values && values.length) selectValue(values[0], option);
    }
  });

  if (showSize) {
    return (
      <VariantButtonWrapper
        className={`variant-buttons-wrapper ${
          showValidationMessage ? 'invalid' : ''
        }`}
      >
        <VariantTitle>
          <VariantHeading className="variant-heading">
            {option.name}
          </VariantHeading>
          {hasSizeGuide && (
            <SizeGuideWrapper>
              <p>&nbsp;|&nbsp;</p>
              <SizeGuideLink
                id="size-guide-link"
                onClick={e => {
                  document.getElementById('tab-header-sizeguide')
                    ? OpenTab('tab-header-sizeguide')
                    : OpenTab('drop-header-sizeguide');
                }}
              >
                <SizeGuideImageWrapper>
                  <Image src="/pub_images/original/storrelse_guide_ikon.png" />
                </SizeGuideImageWrapper>
                {t('Size Guide')}
              </SizeGuideLink>
            </SizeGuideWrapper>
          )}
        </VariantTitle>
        <VariantSizeButtonWrapper className="variant-button-wrapper">
          {option.values.map(size => {
            const validation = validateSelection(size, option);
            if (validation === 'invalid' || !size) {
              return null;
            } else {
              return (
                <StyledButtonWrapper
                  key={size}
                  className={size === selectedValue ? 'selected' : ''}
                >
                  <VariantSizeButton
                    {...size.props}
                    {...(size.variant && size.variant.stockStatus.buyable)}
                    onClick={() => selectValue(size, option)}
                    className={validation === 'outOfStock' ? 'disabled' : ''}
                  >
                    {size}
                  </VariantSizeButton>
                </StyledButtonWrapper>
              );
            }
          })}
        </VariantSizeButtonWrapper>
      </VariantButtonWrapper>
    );
  }
  return null;
};

export default VariantButton;
