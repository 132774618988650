import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import searchFlyoutCategoriesQuery from './SearchFlyoutCategoriesQuery.gql';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theming/Theme';
import { FlyoutTitle } from './SearchFlyout';

const CatList = styled('ul')`
  margin-bottom: 1rem;
`;

const CatItem = styled('li')`
  padding: 0.2rem;
  &:hover {
    background: ${theme.colors.greys[0]};
  }
`;

const CatItemName = styled('h5')`
  font-weight: ${theme.font.weights.bold};
  text-transform: uppercase;
`;

const SearchFlyoutCategories = searchProps => (
  <Query variables={{ levels: 3 }} query={searchFlyoutCategoriesQuery}>
    {result => {
      const { data } = result;
      const categories = data && data.categories;
      const filteredCategories =
        (categories &&
          categories.filter(category => {
            return (
              category.name
                .toLowerCase()
                .search(searchProps.term.toLowerCase()) !== -1
            );
          })) ||
        [];
      return (
        <Fragment>
          {filteredCategories.length ? (
            <CatList>
              <FlyoutTitle>{t('Categories')}</FlyoutTitle>
              {filteredCategories.map(category => (
                <CatItem key={category.id}>
                  <CategoryLink
                    category={category}
                    onClick={searchProps.closeSearch}
                  >
                    <CatItemName>{category.name}</CatItemName>
                  </CategoryLink>
                </CatItem>
              ))}
            </CatList>
          ) : null}
        </Fragment>
      );
    }}
  </Query>
);

export default SearchFlyoutCategories;
