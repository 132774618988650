import React from 'react';
import { styled } from 'linaria/react';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import SearchInput from '../../../../components/Layout/Header/Search/SearchInput';
import SearchFlyout from '../../../../components/Layout/Header/Search/SearchFlyout';
import UICloseButton from '../../../ui/CloseButton';
import autoCompleteQuery from './AutoCompleteQuery.gql';
import SearchIcon from '../../../ui/icons/SearchIcon';
import { theme } from '../../../Theming/Theme';

export const SearchWrapper = styled('div')`
  position: relative;
  height: 100%;
`;

const buttonCss = `
  margin: 0;
  padding: 0;
  right: 1rem;
  top: 0;
  position: absolute;
  height: 100%;
  color: #555;
  opacity: 0.8;
  :focus,
  active {
    outline: none;
  }
  &:hover {
    opacity: 1;
  }
`;

export const CloseButton = styled(UICloseButton)`
  display: none;
  ${theme.below.xl} {
    display: block;
    ${buttonCss}
  }
  display: none !important;
`;

export const SubmitButton = styled('button')`
  ${buttonCss}
  ${theme.below.xl} {
    right: 1.5rem;
  }
`;

export const Wrapper = styled('div')`
  ${theme.below.md} {
    height: 100%;
  }
`;

const SearchField = ({ onCancel }) => (
  <SearchAutoCompleteContainer
    onSubmit={onCancel}
    onCancel={onCancel}
    focusOnLoad={false}
    autocompleteQuery={autoCompleteQuery}
  >
    {({
      getInputProps,
      getFlyoutProps,
      updateFocus,
      triggerSearch,
      ...rest
    }) => (
      <SearchWrapper className="search-wrapper">
        <div className="search-field">
          <SearchInput getInputProps={getInputProps} {...rest} />
          <SubmitButton
            onClick={() => {
              triggerSearch();
            }}
          >
            <SearchIcon />
          </SubmitButton>
        </div>
        <CloseButton className="close-search" onClick={onCancel} />
        <SearchFlyout
          getFlyoutProps={getFlyoutProps}
          closeSearch={onCancel}
          {...rest}
        />
      </SearchWrapper>
    )}
  </SearchAutoCompleteContainer>
);

export default SearchField;
