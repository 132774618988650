import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theme';
import CloseButton from '../../../ui/CloseButton';
import MobileCategories from './MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import PartnerLogos from './PartnerLogos';
import Logo from '../Logo';
import SiteContent from '../../../Util/SiteContent';
import NewsletterField from '../../../Newsletter/NewsletterField';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 17px;
  right: 10px;
  margin: -10px;
  padding: 10px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
  border-right: 1px solid ${theme.colors.border};
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  max-width: 100%;
  width: 200px;
`;

const SiteList = styled('div')`
  margin-top: 2rem;
  padding: 1rem 1rem;
  ul {
    li {
      margin: 0.3rem 0;
    }
  }
`;

const MobileNav = ({ categories, hideTarget, isOpen, right, ...rest }) => (
  <UIDrawer isOpen={isOpen} right={right} size={360}>
    <MobileNavWrapper>
      <StyledDrawerHeader>
        <StyledCloseButton onClick={hideTarget} />
        <LogoWrapper>
          <Logo mobile={true} />
        </LogoWrapper>
      </StyledDrawerHeader>
      <Scroll>
        <MobileCategories
          hideTarget={hideTarget}
          categories={categories}
          isOpen={isOpen}
        />
        <SiteList>
          <SiteContent names={['Footer1']}>
            {(items, renderLink) => {
              return (
                <Fragment>
                  <div>
                    {items.map((item, index) => {
                      const { content } = item;
                      if (content.image != null) {
                        return (
                          <div key={index}>
                            {content.title && (
                              <h3>{renderLink(content.title)}</h3>
                            )}
                            {content.list && (
                              <ul>
                                {content.list.map((listItem, index) => (
                                  <li key={index}>{renderLink(listItem)}</li>
                                ))}
                              </ul>
                            )}
                          </div>
                        );
                      }
                      return (
                        <div key={index}>
                          {content.title && (
                            <h3>{renderLink(content.title)}</h3>
                          )}
                          {content.list && (
                            <ul>
                              {content.list.map((listItem, index) => (
                                <li key={index}>{renderLink(listItem)}</li>
                              ))}
                            </ul>
                          )}
                          {content.newsletter && <NewsletterField />}
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              );
            }}
          </SiteContent>
        </SiteList>
        <PartnerLogos />
      </Scroll>
    </MobileNavWrapper>
  </UIDrawer>
);
export default MobileNav;
