import { styled } from 'linaria/react';
import { theme } from '../Theming/Theme';

const DrawerHeader = styled('div')`
  position: relative;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-bottom: 1px solid #e7e4e4;
  height: ${theme.mobileHeader.height.xs + 'px'};
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
`;

export default DrawerHeader;
