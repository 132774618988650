import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theming/Theme';
import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';
import SiteContent from '../Util/SiteContent';

const IconBarWrapper = styled('div')`
  max-width: 200px;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  opacity: 0.9;
  :hover {
    opacity: 1;
  }
  ${theme.below.md} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 0.7rem;
    @media screen and (max-width: 335px) {
      padding: 0 0.4rem;
    }
  }
`;

const ManyIconBarWrapper = styled('div')`
  max-width: 100px;
  text-align: center;
  margin: 0 auto;
  opacity: 0.9;
  :hover {
    opacity: 1;
  }
`;

const Column = styled('div')`
  width: 7%;
  white-space: none;
  ${theme.below.md} {
    width: auto;
    padding-top: 25px;
  }
  ${theme.only.md} {
    width: 12%;
    padding-top: 25px;
  }
  ${theme.only.lg} {
    width: 9%;
  }
  @media only screen and (max-width: 617px) {
    padding: 0px 28px;
  }
  @media only screen and (max-width: 502px) {
    padding: 0px 26px;
  }
  @media only screen and (max-width: 490px) {
    padding: 0px 12px;
  }
  @media only screen and (max-width: 400px) {
    padding: 0px 0px;
  }
`;

const ManyIconsColumn = styled('li')`
  width: 15%;
  margin: auto;
  padding: 5px;
  ${theme.below.lg} {
    width: 30%;
  }
`;

const Row = styled('div')`
  justify-content: space-between;
  min-height: 200px;
  clear: both;
  display: flex;
  align-items: center;
  text-align: center;
  flex-flow: row wrap;
  padding: 0 3rem 0;
  ${theme.below.sm} {
    padding: 2rem 0 0;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

const ManyIconsRow = styled('ul')`
  min-height: 150px;
  max-width: 850px;
  clear: both;
  display: flex;
  align-items: center;
  text-align: center;
  flex-flow: row wrap;
  margin: auto;
  padding: 5rem 0 4rem;
  ${theme.below.md} {
    max-width: 100%;
  }
  ${theme.below.sm} {
    li:nth-child(n + 7) {
      display: none;
    }
  }
`;
const IconsWrapper = styled('div')`
  background: ${theme.colors.lightgrey};
  padding-bottom: 10px;
`;
const Wrapper = styled('div')`
  background: ${theme.colors.white};
`;
const IconWidthWrapper = styled('div')`
  width: 70px;
  margin: 0 auto;
`;
const Description = styled('p')`
  white-space: nowrap;
`;

export const ManyIconsBar = () => {
  return (
    <SiteContent names={['varemerker-footer-row']}>
      {(items, renderLink) => {
        const itemlist = items;
        return (
          <Wrapper>
            <ManyIconsRow>
              {itemlist.map(listItem =>
                listItem.content.list.map((x, index) => (
                  <ManyIconsColumn key={index}>
                    <ManyIconBarWrapper>
                      <Link to={x.linkUrl}>
                        <Image src={x.imageUrl} aspect="2:1" />
                      </Link>
                    </ManyIconBarWrapper>
                  </ManyIconsColumn>
                ))
              )}
            </ManyIconsRow>
          </Wrapper>
        );
      }}
    </SiteContent>
  );
};

const IconBar = ({ list }) => {
  const item = list;
  return (
    <IconsWrapper>
      <MaxWidth>
        <Row>
          {item.map((listItem, index) => (
            <Column key={index}>
              <IconBarWrapper>
                <Link to={listItem.linkUrl}>
                  <IconWidthWrapper>
                    <Image src={listItem.imageUrl} aspect="1:1" />
                  </IconWidthWrapper>
                  <Description>{listItem.description}</Description>
                </Link>
              </IconBarWrapper>
            </Column>
          ))}
        </Row>
      </MaxWidth>
    </IconsWrapper>
  );
};

export default IconBar;
