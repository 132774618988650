import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as FilledStar } from '../../../svg/FilledStar.svg';
import { ReactComponent as HalfStar } from '../../../svg/HalfStar.svg';
import { ReactComponent as NotFilledStar } from '../../../svg/NotFilledStar.svg';

const LipscoreWrapper = styled('div')`
  text-align: center;
  text-align: -webkit-center;
  min-height: 24px;

  .lipscore-rating-small {
    display: inline;
    white-space: nowrap;
  }

  .lipscore-visually-hidden {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
`;
const StarsWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

const LipscoreStars = ({ rating }) => {
  let stars = [];
  let numbersOfStars = 5;

  for (let y = 0; y < rating; y++) {
    //Check if rating has decimals
    if (rating % 1 !== 0) {
      //If rating is higher than loop value but lower than the next loop integer: push a half star
      if (y === Math.floor(rating) && y !== rating) {
        stars.push(<HalfStar key={y + rating} />);
      } else {
        stars.push(<FilledStar key={y + rating} />);
      }
    } else {
      stars.push(<FilledStar key={y + rating} />);
    }
  }
  //Fill the remaining stars with non-filled stars
  if (stars.length < 5) {
    for (let i = stars.length; i < numbersOfStars; i++) {
      stars.push(<NotFilledStar key={i + rating} />);
    }
  }

  return <StarsWrapper>{stars}</StarsWrapper>;
};

const LipscoreSmallRating = ({ rating }) => {
  if (rating > 3.5)
    return (
      <LipscoreWrapper>
        <span className="lipscore-rating-small">
          <span className="lipscore-visually-hidden" />
          <LipscoreStars rating={rating} />
        </span>
      </LipscoreWrapper>
    );
  return null;
};

export default LipscoreSmallRating;
