import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theming/Theme';
import React, { useContext, useEffect } from 'react';
import { styled } from 'linaria/react';
import { ButtonLinkExt } from '../ui/Button';
import CartItem from './CartItem';
import CloseButton from '../ui/CloseButton';
import DrawerHeader from '../ui/DrawerHeader';
import PartnerLogos from '../Layout/Header/Mobile/PartnerLogos';
import FreeShipping from './FreeShipping';
import { PriceFormatter } from '../ui/PriceFormatter';
import {
  klaviyoAddToCart,
  klaviyoGoToCheckout,
  KlaviyoTrackingContext
} from '../Util/KlaviyoTracking';

const Wrapper = styled('div')`
  background: white;
  color: ${theme.colors.black};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
  position: absolute;
  right: 3px;
  top: 30px;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  margin: 0 1.5rem;
  padding: 0.5rem 0;
  align-items: flex-start;
  justify-content: flex-end;
  h2 {
    font-size: 1.4rem;
  }
`;
const Summary = styled('section')`
  background: #fff;
  padding: 1rem 1.5rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
`;
const CartItems = styled('section')`
  padding: 1rem 1.5rem;
  overflow-y: auto;
  flex-grow: 1;
`;

const TotalSummary = styled('div')`
  font-size: 1.25rem;
  font-family: ${theme.font.families.heavy};
  color: ${theme.colors.font};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SummaryTitle = styled('h3')`
  text-transform: none;
  font-size: 1.2rem;
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled(ButtonLinkExt)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  text-transform: uppercase;
`;

const CartContent = ({ result, modal, ...rest }) => {
  const cart = result?.data?.cart;
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { latestAdded } = useContext(KlaviyoTrackingContext);
  useEffect(() => {
    klaviyoAddToCart(latestAdded, items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestAdded]);

  if (items.length === 0) {
    return (
      <Wrapper {...rest}>
        <StyledDrawerHeader>
          <h2>{t('No items in cart.')}</h2>
          <StyledCloseButton onClick={modal.hideTarget} />
        </StyledDrawerHeader>
      </Wrapper>
    );
  }
  return (
    <Wrapper {...rest}>
      <StyledDrawerHeader>
        <h2>{t('Your Cart')}</h2>
        <StyledCloseButton onClick={modal.hideTarget} />
      </StyledDrawerHeader>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} close={modal.hideTarget} />
        ))}
      </CartItems>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h4>{t('Cart total')}</h4>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h4>{t('Discounts')}</h4>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name}>
                  <label>{discount.name}</label>
                  <Price price={discount.value} css={{ display: 'inline' }} />
                </Flex>
              );
            })}
          </div>
        )}
        <TotalSummary>
          <SummaryTitle>{t('Total')}</SummaryTitle>
          <PriceFormatter
            price={result.data.cart.productTotal}
            previousPrice={result.data.productPreviousTotal}
            showDiscount={false}
            code={',-'}
            culture={'nb-NO'}
          />
        </TotalSummary>
        <FreeShipping
          className="shipping"
          cartTotal={result.data.cart.productTotal}
        />
        {checkoutUrl && (
          <Checkout
            className="buy"
            href={checkoutUrl}
            onClick={event => {
              klaviyoGoToCheckout({ cart: result.data.cart });
              event.preventDefault();
              track(
                trackCartCheckoutEvent({
                  cart: cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
      <PartnerLogos />
    </Wrapper>
  );
};

export default CartContent;
