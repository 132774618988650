import React, { useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import SubMenu from './SubMenu';
import { theme } from '../../../Theming/Theme';
import { Query } from 'react-apollo';
import deepCategoriesQuery from '../Categories/DeepCategoriesQuery.gql';
import { ExtraMenuLinks } from './ExtraMenuLinks';
import ActivityMenu from './ActivityMenu';

const InvisibleDivider = styled('div')`
  width: 60px;
  height: 40px;
  visibility: hidden;
`;

const DesktopNavWrapper = styled('div')`
  height: ${theme.desktopHeader.nav.height.lg + 'px'};
  transition: height 0.8s ease;
  float: left;
  margin-top: 32px;
  max-width: 100%;
  overflow: hidden;
  ${theme.only.md} {
    height: ${theme.desktopHeader.nav.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.nav.height.scroll + 'px'};
    margin-top: 10px;
  }
  li:first-child {
    padding-left: 0;
  }
`;

const List = styled('ul')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  > li {
    list-style: none;
    padding: 0 ${theme.desktopHeader.nav.padding};
    > a,
    > .category-with-subs {
      position: relative;
      height: 100%;
      display: block;
      font-family: ${theme.font.families.heavy};
      font-weight: ${theme.font.weights.normal};
      letter-spacing: ${theme.font.header.letterSpacing};
      text-transform: none;
      font-size: ${theme.desktopHeader.nav.fontSize};
      line-height: ${theme.desktopHeader.nav.height.lg + 'px'};
      ${theme.only.md} {
        line-height: ${theme.desktopHeader.nav.height.md + 'px'};
      }
      //color: white;
    }
    a.active {
      text-decoration: underline;
      color: ${theme.colors.primary};
    }
    a.selected {
      text-decoration: underline;
    }
    &.sub-menu-open {
      .sub-menu-wrapper {
        display: block;
      }
    }
  }
  &[data-scrolling='true'] {
    > li {
      > a {
        line-height: ${theme.desktopHeader.nav.height.scroll + 'px'};
      }
    }
  }
`;

const SubMenuIcon = styled('span')`
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 100%;
  text-align: center;
  &[data-scrolling='true'] {
    bottom: -18px;
  }
`;

const CategorySpan = styled('span')`
  cursor: pointer;
`;

function handleCatOpen(e, category, setActiveCategory, setSubMenu) {
  if (category.hasSubcategories) {
    e.preventDefault();
    setActiveCategory(category);
    setSubMenu(true);
  } else setSubMenu(false);
}

const NavItem = ({
  category,
  active,
  setActiveCategory,
  subMenuOpen,
  setSubMenu,
  scrolling
}) => {
  const [fetchCategories, setFetch] = useState(false);
  const isActivityCategory =
    category?.name === 'Aktiviteter' || category?.id === 2710;
  const isCampaignCategory =
    category?.name === 'Kampanjer' || category?.id === 3178;

  if (isCampaignCategory) {
    return (
      <CategorySpan className="category-with-subs">
        <CategoryLink
          category={category}
          style={{ textTransform: 'uppercase' }}
        >
          {category.name}
        </CategoryLink>
      </CategorySpan>
    );
  }

  return (
    <li
      key={category.id}
      className={subMenuOpen && active ? 'sub-menu-open' : ''}
    >
      {category.hasSubcategories ? (
        <CategorySpan
          className="category-with-subs"
          onMouseEnter={() => {
            setFetch(true);
          }}
          onClick={e =>
            handleCatOpen(e, category, setActiveCategory, setSubMenu)
          }
        >
          {category.name}
          {subMenuOpen && active && <SubMenuIcon data-scrolling={scrolling} />}
        </CategorySpan>
      ) : (
        <CategorySpan className="category-with-subs">
          <CategoryLink
            category={category}
            style={{ textTransform: 'uppercase' }}
          >
            {category.name}
          </CategoryLink>
        </CategorySpan>
      )}
      {fetchCategories && (
        <Query
          variables={{ root: category.id, levels: 1 }}
          query={deepCategoriesQuery}
        >
          {result => {
            const { data } = result;
            const category =
              (data && data.categories && data.categories[0]) || false;

            if (!subMenuOpen || !active || !category) return null;

            if (isActivityCategory) {
              return (
                <ActivityMenu
                  category={category}
                  setActiveCategory={setActiveCategory}
                  setActivityMenu={setSubMenu}
                  data-scrolling={scrolling}
                />
              );
            }

            return (
              <SubMenu
                category={category}
                setActiveCategory={setActiveCategory}
                setSubMenu={setSubMenu}
                data-scrolling={scrolling}
              />
            );
          }}
        </Query>
      )}
    </li>
  );
};

const DesktopNav = ({ categoryTreeRoots, scrolling }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [subMenuOpen, setSubMenu] = useState(false);

  const dividerIndex = categoryTreeRoots.findIndex(
    category => category?.name?.indexOf('=====') !== -1
  );
  const leftCategories = categoryTreeRoots.slice(0, dividerIndex);
  const rightCategories = categoryTreeRoots.slice(dividerIndex + 1);

  return (
    <DesktopNavWrapper
      data-scrolling={scrolling}
      //onMouseLeave={() => setSubMenu(false)}
    >
      <List data-scrolling={scrolling}>
        {leftCategories.map(category => {
          return (
            <NavItem
              key={category.id}
              active={activeCategory && activeCategory.id === category.id}
              category={category}
              setActiveCategory={setActiveCategory}
              subMenuOpen={subMenuOpen}
              setSubMenu={setSubMenu}
              scrolling={scrolling}
            />
          );
        })}
        <InvisibleDivider />
        {rightCategories.map(category => {
          return (
            <NavItem
              key={category.id}
              active={activeCategory && activeCategory.id === category.id}
              category={category}
              setActiveCategory={setActiveCategory}
              subMenuOpen={subMenuOpen}
              setSubMenu={setSubMenu}
              scrolling={scrolling}
            />
          );
        })}
        <ExtraMenuLinks />
      </List>
    </DesktopNavWrapper>
  );
};

export default DesktopNav;
