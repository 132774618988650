import React, { Fragment, useState, useEffect, useContext } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import MaxWidth from '../../MaxWidth';
import CloseButton from '../../../ui/CloseButton';
import { theme } from '../../../Theming/Theme';
import t from '@jetshop/intl';
// import { faPlus } from '@fortawesome/pro-light-svg-icons';
// import { faMinus } from '@fortawesome/pro-light-svg-icons';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';

const SubMenuWrapper = styled('div')`
  display: none;
  max-height: calc(100vh - ${theme.header.height.lg + 'px'});
  overflow: auto;
  position: absolute;
  background: white;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 1.5rem 0 3rem;
  border-top: 1px solid ${theme.colors.border};
  border-bottom: 1px solid ${theme.colors.border};
  ${theme.only.md} {
    top: ${theme.desktopHeader.nav.height.md - 1 + 'px'};
  }
`;

const SubMenuTitle = styled('h2')`
  text-align: left;
  border-bottom: 1px solid ${theme.colors.border};
  display: inline-block;
  width: 100%;
  clear: both;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  a {
    text-decoration: none !important;
    font-weight: ${theme.font.weights.bold} !important;
  }
`;
const SubMenuTitleName = styled('span')``;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 16px;
  top: 0.3rem;
  z-index: 2;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const SubMenuContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  .sub-menu-categories {
    width: 100%;
  }
  .sub-menu-products {
    //width: 70%;
  }
`;

const SubMenuCategories = styled('div')`
  //LEVEL2
  > ul {
    margin: 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    > li {
      list-style: none;
      width: 20%;
      ${theme.only.lg} {
        width: 25%;
      }
      line-height: 2em;
      -webkit-column-break-inside: avoid; /* Chrome, Safari */
      page-break-inside: avoid; /* Theoretically FF 20+ */
      break-inside: avoid-column; /* IE 11 */
      display: table; /* Actually FF 20+ */
      > div {
        position: relative;
        margin: 1rem 0 1rem 47px;
        padding: 0 0.3rem 0 0.7rem;
        ${theme.only.lg} {
          margin: 1rem 0 1rem 25px;
          padding: 0 0.2rem 0 0.5rem;
        }
        border-left: 1px solid ${theme.colors.border};
        > a {
          font-family: ${theme.font.families.heavy};
          text-transform: uppercase;
          font-weight: ${theme.font.weights.bold};
          font-size: 1rem;
          line-height: 1.2;
          display: block;
          margin-bottom: 0.25rem;
        }
        > a:hover {
          color: #df191e;
        }
        //LEVEL3
        > ul {
          > li {
            line-height: 1.5em;
            > div {
              > a {
                font-size: 0.9rem;
              }
              > a:hover {
                color: #df191e;
              }
              //LEVEL4
              > ul {
                display: none;
                > li {
                }
              }
            }
          }
        }
      }
    }
  }
  li.open {
    > ul {
      display: block !important;
    }
  }
  li.show-all {
    font-size: 0.9rem;
    a:hover {
      color: #df191e;
    }
  }
`;

// const StyledIcon = styled(FontAwesomeIcon)`
//   margin-left: 2rem;
//   cursor: pointer;
// `;

const IconWrapper = styled('div')`
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: absolute;
  left: -60px;
  top: -5px;
  background-position: center center;
  background-size: cover;

  ${theme.only.lg} {
    width: 25px;
    left: -32px;
  }
`;

const IconImage = ({ categoryId }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const baseUrl = selectedChannel?.url;
  const src = `${baseUrl}/pub_docs/files/icons/category-${categoryId}.svg`;

  return <IconWrapper style={{ backgroundImage: `url(${src})` }} />;
};

const SubCategories = ({
  mainCategory,
  level,
  setActiveCategory,
  setSubMenu,
  toggleMenu,
  setToggleMenu
}) => {
  let limiter = 300;
  if (mainCategory.subcategories && mainCategory.subcategories.length)
    return (
      <React.Fragment>
        {mainCategory.subcategories.map((category, index) => (
          <React.Fragment key={category.id}>
            {index <= limiter && (
              <li className={category.id === toggleMenu ? 'open' : ''}>
                <div>
                  {level === 2 ? <IconImage categoryId={category.id} /> : null}
                  <CategoryLink
                    onClick={() => {
                      setSubMenu(false);
                      setActiveCategory(category);
                    }}
                    category={category}
                  >
                    {category.name}
                  </CategoryLink>
                  {category.subcategories &&
                    category.subcategories.length > 0 &&
                    (mainCategory.name !== 'Merker' ? (
                      <Fragment>
                        <ul>
                          <SubCategories
                            mainCategory={category}
                            subcategories={category.subcategories}
                            level={level + 1}
                            setActiveCategory={setActiveCategory}
                            setSubMenu={setSubMenu}
                            toggleMenu={toggleMenu}
                            setToggleMenu={setToggleMenu}
                          />
                        </ul>
                      </Fragment>
                    ) : (
                      <span></span>
                    ))}
                </div>
              </li>
            )}
          </React.Fragment>
        ))}
        {level === 3 ? (
          <li className="show-all">
            <CategoryLink
              onClick={() => {
                setSubMenu(false);
                setActiveCategory(mainCategory);
              }}
              category={mainCategory}
            >
              {t('Show all')}
            </CategoryLink>
          </li>
        ) : null}
      </React.Fragment>
    );
  else return null;
};

const SubMenu = ({ category, setActiveCategory, setSubMenu }) => {
  const [toggleMenu, setToggleMenu] = useState(0);
  const subMenuRef = React.createRef();
  useEffect(() => {
    const listener = e => {
      if (!subMenuRef.current) return;
      if (!subMenuRef.current.contains(e.target)) {
        setSubMenu(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  });
  if (category && category.subcategories && category.subcategories.length) {
    return (
      <React.Fragment>
        <SubMenuWrapper className="sub-menu-wrapper">
          <div ref={subMenuRef}>
            <MaxWidth>
              <StyledCloseButton onClick={() => setSubMenu(false)} />
              <SubMenuTitle>
                <CategoryLink
                  category={category}
                  onClick={() => {
                    setActiveCategory(category);
                    setSubMenu(false);
                  }}
                >
                  <React.Fragment>
                    <SubMenuTitleName>{category.name}</SubMenuTitleName>
                  </React.Fragment>
                </CategoryLink>
              </SubMenuTitle>
              <SubMenuContent>
                <SubMenuCategories className="sub-menu-categories">
                  <ul>
                    <SubCategories
                      mainCategory={category}
                      level={2}
                      setActiveCategory={setActiveCategory}
                      setSubMenu={setSubMenu}
                      toggleMenu={toggleMenu}
                      setToggleMenu={setToggleMenu}
                    />
                  </ul>
                </SubMenuCategories>
              </SubMenuContent>
            </MaxWidth>
          </div>
        </SubMenuWrapper>
      </React.Fragment>
    );
  } else return null;
};

export default SubMenu;
