import React from 'react';
import { styled } from 'linaria/react';
import CampaignBarComp from './CampaignBar';

const ContentWrapper = styled('main')`
  background: #fff;
  flex: 1 1 auto;
`;

const MainBackground = styled('div')``;

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <CampaignBarComp />
      <MainBackground>{children}</MainBackground>
    </ContentWrapper>
  );
};

export default Content;
