export const config = {
  channelId:
    typeof process.env.REACT_APP_CHANNEL_ID !== 'undefined'
      ? parseInt(process.env.REACT_APP_CHANNEL_ID, 10)
      : 1,
  siteContent: {
    id: parseInt(process.env.REACT_APP_CHANNEL_ID, 10) === 2 ? 594 : 474
  },
  addwish: {
    productPage: [],
    notFoundPage: ['5cc1c533f8bed8129f05cfbb', '5cc1c534f8bed8129f05cfd4'],
    cartPopup: 'k608a5d3781100a34165e92d3'
  },
  routes: {
    favourites: {
      path: '/favourites'
    }
  }
};
