import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import CartButton from '../../../Cart/CartButton';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';
import { Inner, PagePadding, PagePaddingWrapper } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import Logo from '../Logo';
import MenuIcon from '../../../ui/icons/MenuIcon';
import { theme } from '../../../Theming/Theme';

const MobileHeaderWrapper = styled('div')`
  background: white;
  height: ${theme.mobileHeader.height.xs + 'px'};
  border-bottom: 1px solid ${theme.colors.border};
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
  ul.top-nav-list {
    height: 100%;
    align-items: center;
    > li > a,
    > li > button {
    }
  }
  ${PagePaddingWrapper},
  ${Inner} {
    height: 100%;
  }
  ${Inner} {
    display: flex;
    align-items: center;
  }
  .header-icon {
    font-size: 26px;
  }
  .header-button {
    position: relative;
    .number-badge {
      right: -4px;
      bottom: 3px;
      top: auto;
    }
  }
`;

const StoreLogo = styled('div')`
  text-align: center;
  text-decoration: none;
  color: inherit;
  width: 160px;
  margin: 0 auto 0;
  a {
    text-decoration: none;
    > img,
    > svg {
      max-width: 100%;
    }
  }
`;

const MenuBar = styled('div')`
  position: relative;
  height: ${theme.mobileHeader.height.xs + 'px'};
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
`;

const HeaderButton = styled('button')`
  display: flex;
`;

const SearchFieldWrapper = styled('div')`
  width: 100%;
  position: fixed;
  left: 0;
  background: #fff;
  top: ${theme.topBar.height.sm + theme.mobileHeader.height.sm + 1 + 'px'};
  z-index: 1;
  height: ${theme.mobileHeader.search.height.sm - 1 + 'px'};
  padding: 5px 7px;
  &[data-scrolling='true'] {
    height: ${theme.mobileHeader.height.sm - 1 + 'px'};
    position: absolute;
    padding: 10px 0;
    top: 0;
    width: calc(100% - 122px);
    left: 33px;
    input {
      height: 34px;
    }
  }
  .search-wrapper {
    height: 100%;
    .search-field {
      height: 100%;
      border-radius: 30px;
      border: 1px solid #333;
    }
  }

  input {
    height: ${theme.mobileHeader.search.height.sm - 17 + 'px'};
    border-bottom: none;
    background: transparent;
  }
`;

const CartWrapper = styled('div')`
  display: flex;
  > *:first-child {
    margin-right: 10px;
  }
`;

const MobileHeader = ({ setSearch, searchOpen, scrolling }) => {
  return (
    <MobileHeaderWrapper>
      <MenuBar>
        <PagePadding>
          <Inner>
            <TopNav left>
              <DrawerTrigger id="menuDrawer" coverStyles={{ zIndex: 101 }}>
                {({ showTarget, hideTarget, isOpen }) => (
                  <HeaderButton onClick={isOpen ? hideTarget : showTarget}>
                    <MenuIcon className="header-icon" />
                  </HeaderButton>
                )}
              </DrawerTrigger>
            </TopNav>
            <SearchFieldWrapper data-scrolling={scrolling}>
              <SearchField
                onCancel={() => setSearch(false)}
                onOpen={() => setSearch(true)}
                isOpen={searchOpen}
                iconSize={26}
              />
            </SearchFieldWrapper>
            <StoreLogo>
              <Link to="/">
                <Logo mobile={true} />
              </Link>
            </StoreLogo>
            <TopNav right>
              <CartWrapper>
                <FavouriteCount className="header-button" />
                <CartButton className="header-icon" />
              </CartWrapper>
            </TopNav>
          </Inner>
        </PagePadding>
      </MenuBar>
    </MobileHeaderWrapper>
  );
};

export default MobileHeader;
