import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import MaxWidth from '../../MaxWidth';
import CloseButton from '../../../ui/CloseButton';
import { theme } from '../../../Theming/Theme';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';

const ActivityMenuWrapper = styled('div')`
  display: none;
  max-height: calc(100vh - ${theme.header.height.lg + 'px'});
  overflow: auto;
  position: absolute;
  background: white;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 1.5rem 0 3rem;
  border-top: 1px solid ${theme.colors.border};
  border-bottom: 1px solid ${theme.colors.border};
  ${theme.only.md} {
    top: ${theme.desktopHeader.nav.height.md - 1 + 'px'};
  }
`;

const ActivityMenuTitle = styled('h2')`
  text-align: center;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  clear: both;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  font-size: 2.1rem;
  a {
    text-decoration: none !important;
    font-weight: ${theme.font.weights.bold} !important;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 16px;
  top: 0.3rem;
  z-index: 2;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const ActivityMenuContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  .sub-menu-categories {
    width: 100%;
  }
`;

const ActivityMenuCategories = styled('div')`
  //LEVEL2
  > ul {
    margin: 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    > li {
      list-style: none;
      width: 20%;
      ${theme.only.lg} {
        width: 25%;
      }
      line-height: 2em;
      -webkit-column-break-inside: avoid; /* Chrome, Safari */
      page-break-inside: avoid; /* Theoretically FF 20+ */
      break-inside: avoid-column; /* IE 11 */
      display: table; /* Actually FF 20+ */
      > div {
        position: relative;
        margin: 1rem 0 1rem 47px;
        padding: 0 0.3rem 0 0.7rem;
        ${theme.only.lg} {
          margin: 1rem 0 1rem 25px;
          padding: 0 0.2rem 0 0.5rem;
        }
        border-left: 1px solid ${theme.colors.border};
        > a {
          font-family: ${theme.font.families.heavy};
          text-transform: uppercase;
          font-weight: ${theme.font.weights.bold};
          font-size: 1rem;
        }
        > a:hover {
          color: #df191e;
        }
      }
    }
  }
  li.open {
    > ul {
      display: block !important;
    }
  }
  li.show-all {
    font-size: 0.9rem;
    a:hover {
      color: #df191e;
    }
  }
`;

const ImageCard = styled('div')`
  position: relative;
  > div {
    width: 100%;
    height: 100%;
  }
  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
    font-size: max(20px, 1.25vw);
    font-weight: bold;
    text-shadow: 1px 1px 4px #000000;
    text-transform: uppercase;
    word-break: keep-all !important;
    color: ${theme.colors.white};
  }
`;

const SubCategories = ({
  category,
  level,
  setActiveCategory,
  setActivityMenu,
  toggleMenu,
  setToggleMenu
}) => {
  if (!category.subcategories || !category.subcategories.length) {
    return null;
  }

  let limiter = 300;

  return category.subcategories.map((subCategory, index) => {
    if (index >= limiter) return null;

    const subCategoryImage =
      subCategory.images?.find(img => img.url.indexOf('/original/') > -1)
        ?.url || '';

    return (
      <li
        key={subCategory.id}
        className={subCategory.id === toggleMenu ? 'open' : undefined}
      >
        <CategoryLink
          onClick={() => {
            setActivityMenu(false);
          }}
          category={subCategory}
        >
          <ImageCard>
            <Image
              src={subCategoryImage}
              aspect={'5:3'}
              crop
              cover
              sizes={[300]}
            />
            <span>{subCategory.name}</span>
          </ImageCard>
        </CategoryLink>
      </li>
    );
  });
};

const ActivityMenu = ({ category, setActiveCategory, setActivityMenu }) => {
  const [toggleMenu, setToggleMenu] = useState(0);
  const subMenuRef = React.createRef();

  useEffect(() => {
    const listener = e => {
      if (!subMenuRef.current) return;
      if (!subMenuRef.current.contains(e.target)) {
        setActivityMenu(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  });

  if (!category || !category.subcategories || !category.subcategories?.length) {
    return null;
  }

  return (
    <React.Fragment>
      <ActivityMenuWrapper className="sub-menu-wrapper">
        <div ref={subMenuRef}>
          <MaxWidth>
            <StyledCloseButton onClick={() => setActivityMenu(false)} />
            <ActivityMenuTitle>
              {t('What are you hooked on?')}
            </ActivityMenuTitle>
            <ActivityMenuContent>
              <ActivityMenuCategories className="sub-menu-categories">
                <ul>
                  <SubCategories
                    category={category}
                    level={2}
                    setActiveCategory={setActiveCategory}
                    setActivityMenu={setActivityMenu}
                    toggleMenu={toggleMenu}
                    setToggleMenu={setToggleMenu}
                  />
                </ul>
              </ActivityMenuCategories>
            </ActivityMenuContent>
          </MaxWidth>
        </div>
      </ActivityMenuWrapper>
    </React.Fragment>
  );
};

export default ActivityMenu;
