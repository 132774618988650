import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../../Cart/CartQuery.gql';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theming/Theme';
import React from 'react';
import { styled } from 'linaria/react';
import Button from '../../ui/Button';
import { ListPrice } from '../../CategoryPage/ProductCard';
import useProductToast from './useProductToast';

const Container = styled('aside')`
  ${theme.above.sm} {
    width: 320px;
  }
  ${theme.below.sm} {
    width: 100%;
  }
  background-color: ${theme.colors.white};
  line-height: 1em;
`;

const CheckoutButton = styled(Button)``;

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme.space[2]};
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
`;

const Header = styled('h5')`
  font-family: ${theme.font.families.heavy};
  margin-bottom: 0.5rem;
`;

const ProductName = styled('p')`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

const VariantText = styled('span')`
  &:before {
    content: '-';
    margin: 0 3px;
  }
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: ${theme.fontSizes[0]};
`;

const ProductToast = ({
  product,
  cart,
  selectedVariation,
  quantity,
  error
}) => {
  const selected = selectedVariation || product;
  const { price, previousPrice, image } = useProductToast({
    product,
    selectedVariation,
    quantity
  });
  const track = useTracker();
  return (
    <Container>
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image src={image.url} sizes={100} aspect="1:1" alt={image.alt} />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : (
              <Header>{t('Added to bag')}</Header>
            )}
            <ProductName>
              {product.name}
              {selected.values && selected.values.length > 0 && (
                <VariantText>{selected.values[0]}</VariantText>
              )}
              {selected.values && selected.values.length > 1 && (
                <VariantText>{selected.values[1]}</VariantText>
              )}
            </ProductName>

            {!error && (
              <ListPrice
                price={price.price}
                recommendedPrice={price.recommendedPrice}
                previousPrice={previousPrice.previousPrice}
              />
            )}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <CheckoutButton buy>
            <a
              href={cart.externalCheckoutUrl}
              onClick={event => {
                event.preventDefault();
                track(
                  trackCartCheckoutEvent({
                    cart: cart,
                    callback: () => {
                      window.location = cart.externalCheckoutUrl;
                    }
                  })
                );
              }}
            >
              {t('To checkout')}
            </a>
          </CheckoutButton>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
