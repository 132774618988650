import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { PriceFormatter } from '../ui/PriceFormatter';

const Bar = styled('div')`
  position: relative;
  background: #fed000;
  height: 30px;
  margin-bottom: 1rem;
  &,
  .price {
    color: white;
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: #000;
    .price {
      color: #000;
      margin-right: 5px;
    }
  }
`;

const Progress = styled('div')`
  position: absolute;
  height: 100%;
  right: 0;
  background: #ffe338;
`;

function FreeShipping({ cartTotal, ...rest }) {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  const freeShippingAmount = cartTotal?.incVat + untilLimit?.incVat;
  const percent = hasMetLimit
    ? 0
    : (untilLimit?.incVat / freeShippingAmount) * 100;

  if (!freeShippingConfigured) return null;

  return (
    <Bar>
      <div className="text">
        {hasMetLimit
          ? t('Free for your order')
          : t.rich(`{price} to free shipping!`, {
              price: (
                <PriceFormatter
                  key={1}
                  price={untilLimit}
                  showDiscount={false}
                  code={',-'}
                  culture={'nb-NO'}
                />
              )
            })}
      </div>
      <Progress style={{ width: percent + '%' }} />
    </Bar>
  );
}

export default FreeShipping;
