import React from 'react';
import { styled } from 'linaria/react';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import { theme } from '../../../Theming/Theme';
import ProductLink from '@jetshop/ui/ProductLink';
import { ListPrice } from '../../../CategoryPage/ProductCard';

const SearchProductWrapper = styled('li')`
  padding: 1rem 0.3rem;
  border-bottom: 1px solid ${theme.colors.border};
  &:hover {
    background: ${theme.colors.greys[0]};
  }
`;

const ProductImageWrapper = styled('div')`
  width: 15%;
  .image-holder {
  }
`;

const StyledProductLink = styled(ProductLink)`
  display: flex;
`;

const ProductInfo = styled('div')`
  width: 80%;
  padding-left: 1rem;
  h3 {
    font-size: 0.9rem;
  }
  > div {
    font-weight: ${theme.font.weights.bold};
  }
`;

const StyledListPrice = styled(ListPrice)`
  > div {
    font-size: 0.9rem !important;
  }
`;

const SearchProduct = ({ product, onClose, term }) => {
  return (
    <SearchProductWrapper>
      <StyledProductLink product={product} onClick={onClose}>
        <ProductImageWrapper>
          <ProductImage
            className="image-holder"
            image={product.images[0]}
            sizes={[200]}
            aspect={'1:1'}
          />
        </ProductImageWrapper>
        <ProductInfo>
          <h3>{product.name}</h3>
          <StyledListPrice
            price={product.price}
            recommendedPrice={product.recommendedPrice}
            previousPrice={product.previousPrice}
          />
        </ProductInfo>
      </StyledProductLink>
    </SearchProductWrapper>
  );
};

export default SearchProduct;
