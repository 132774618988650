import React from 'react';
import { usePrice } from '@jetshop/core/hooks/usePrice';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theme';

const PriceFormatterWrapper = styled('div')`
  &.negative {
    display: flex;
    &:before {
      content: '-';
      font-size: 1.25em;
      margin-right: 5px;
    }
  }
`;

const NewPrice = styled('div')`
  display: block;
  color: ${theme.colors.discount};
`;
const OldPrice = styled('p')`
  display: block;
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
  color: ${theme.colors.greys[4]};
`;
const NormalPrice = styled('div')`
  color: ${theme.colors.black};
`;

function formatter(price, code) {
  return `${price} ${code}`;
}

export function PriceFormatter({
  className = '',
  price,
  previousPrice,
  code = ',-',
  culture = 'nb-NO',
  showDiscount = true
}) {
  const prices = usePrice({
    currencyOverride: { code: code, culture: culture },
    price: price,
    previousPrice: previousPrice,
    formatter
  });

  let priceObject = prices.pricing;
  let hasDiscount;
  if (priceObject.price) {
    if (priceObject.price.value === priceObject.previousPrice.value) {
      hasDiscount = false;
    } else {
      hasDiscount = true;
    }
  }

  return (
    <PriceFormatterWrapper className={`price-formatter-wrapper ${className}`}>
      {hasDiscount && showDiscount ? (
        <div className="price-with-discount">
          <NewPrice className="price">{prices.formattedPricing.price}</NewPrice>
          <OldPrice className="old-price">
            {prices.formattedPricing.previousPrice}
          </OldPrice>
        </div>
      ) : (
        <NormalPrice className="price price-standard">
          {prices.formattedPricing.price}
        </NormalPrice>
      )}
    </PriceFormatterWrapper>
  );
}
