import React from 'react';
import SiteContent from '../../Util/SiteContent';
import { styled } from 'linaria/react';
import NewsletterField from '../../Newsletter/NewsletterField';
import { theme } from '../../Theming/Theme';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  > div {
    width: 100%;
    ${theme.above.lg} {
      width: 100%;
    }
    &.text-wrapper {
      background: ${theme.colors.lightgrey};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1rem;
      h2,
      h3,
      h4 {
        color: ${theme.colors.black};
      }
      p {
        color: ${theme.colors.grey};
        max-width: 850px;
        text-align: left;
      }
      div {
        > p {
          color: ${theme.colors.black};
          text-align: center;
        }
        button {
          background: ${theme.colors.white};
          color: ${theme.colors.font};
          font-family: ${theme.font.families.heavy};
          text-transform: uppercase;
          font-size: 1.5rem;
          opacity: 1 !important;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  return (
    <SiteContent names={['NewsletterBox']}>
      {(items, renderLink) => {
        const item = items[0];
        return (
          <>
            {item && (
              <NewsletterBoxWrapper>
                <div className="text-wrapper">
                  {item.content && (
                    <>
                      <h4>{item.content.subTitle}</h4>
                      <h3>{item.content.title}</h3>
                    </>
                  )}
                  <NewsletterField />
                  {item.content && (
                    <>
                      <p>{item.content.paragraph}</p>
                    </>
                  )}
                </div>
              </NewsletterBoxWrapper>
            )}
          </>
        );
      }}
    </SiteContent>
  );
};

export default NewsletterBox;
