import React from 'react';
import { styled } from 'linaria/react';
import { standardColors } from './standardColors';

export const ColorWrapper = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
`;

function getHexColor(colors) {
  return colors[0][0];
}

function getTwoHexColors(colors) {
  var hexColor1 = colors[0][0];
  var hexColor2 = colors[1][0];
  return (
    'linear-gradient(to right, ' +
    hexColor1 +
    ' 0%, ' +
    hexColor1 +
    ' 50%, ' +
    hexColor2 +
    ' 50%, ' +
    hexColor2 +
    ' 100%)'
  );
}
function getThreeHexColors(colors) {
  var hexColor1 = colors[0][0];
  var hexColor2 = colors[1][0];
  var hexColor3 = colors[2][0];
  return (
    'linear-gradient(to right, ' +
    hexColor1 +
    ' 0%, ' +
    hexColor1 +
    ' 33%, ' +
    hexColor2 +
    ' 33%, ' +
    hexColor2 +
    ' 66%, ' +
    hexColor3 +
    ' 66%, ' +
    hexColor3 +
    ' 100%)'
  );
}
function getFourHexColors(colors) {
  var hexColor1 = colors[0][0];
  var hexColor2 = colors[1][0];
  var hexColor3 = colors[2][0];
  var hexColor4 = colors[3][0];
  return (
    'linear-gradient(to right, ' +
    hexColor1 +
    ' 0%, ' +
    hexColor1 +
    ' 25%, ' +
    hexColor2 +
    ' 25%, ' +
    hexColor2 +
    ' 50%, ' +
    hexColor3 +
    ' 50%, ' +
    hexColor3 +
    ' 75%, ' +
    hexColor4 +
    ' 75%, ' +
    hexColor4 +
    ' 100%)'
  );
}

//HELPERS
function checkNumberOfColors(value) {
  const regex = RegExp('#[A-Za-z0-9]*', 'g');
  let re = [];
  let colors = [];
  while ((re = regex.exec(value)) != null) {
    colors.push(re);
  }
  return colors;
}

export const Circle = ({ value, className = '' }) => {
  const colors = checkNumberOfColors(value);
  if (value && colors.length === 0) {
    return (
      <ColorWrapper
        style={{ backgroundColor: standardColors(value) }}
        className={className}
      />
    );
  } else if (colors.length === 1) {
    return (
      <ColorWrapper
        style={{ backgroundColor: getHexColor(colors) }}
        className={className}
      />
    );
  } else if (colors.length === 2) {
    return (
      <ColorWrapper
        style={{ backgroundImage: getTwoHexColors(colors) }}
        className={className}
      />
    );
  } else if (colors.length === 3) {
    return (
      <ColorWrapper
        style={{ backgroundImage: getThreeHexColors(colors) }}
        className={className}
      />
    );
  } else if (colors.length === 4) {
    return (
      <ColorWrapper
        style={{ backgroundImage: getFourHexColors(colors) }}
        className={className}
      />
    );
  }
  return null;
};
