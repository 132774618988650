import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { Circle } from './Circle';
import ProductLink from '@jetshop/ui/ProductLink';
import PlusIcon from '../../ui/icons/PlusIcon';
import t from '@jetshop/intl';
import { ToolTip } from './ToolTip';
import { theme } from '../../Theming/Theme';

//STYLED COMPONENTS
const ColorCircleWrapper = styled('div')`
  position: relative;
  ${theme.below.md} {
    &:nth-of-type(n + 13) {
      display: none;
    }
  }
`;

const ColorCirclesWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  ${ColorCircleWrapper} {
    margin: 0 2px;
    display: flex;
    justify-content: center;
    margin-bottom: 4px;
  }
  &.many-colors {
    ${ColorCircleWrapper} {
      &:first-child,
      &:nth-of-type(2) {
        .tool-tip {
          left: -10px;
          transform: translateX(0) translateY(0);
          &:after {
            left: 14px;
            transform: translateX(0) translateY(0);
          }
        }
      }
      &:last-child,
      &:nth-last-child(2) {
        .tool-tip {
          left: auto;
          right: -10px;
          transform: translateX(0) translateY(0);
          &:after {
            left: auto;
            right: 14px;
            transform: translateX(0) translateY(0);
          }
        }
      }
    }
  }
`;

const MoreColors = styled('div')`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-left: 6px;
  margin-bottom: 4px;
  overflow: hidden;
  svg {
    color: #888;
    font-size: 1rem;
  }
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

const ToolTipText = styled('p')`
  font-size: 0.8rem;
  > span {
    &:not(:last-child):after {
      content: '/';
      margin: 0 2px;
    }
  }
`;

const ToolTipTitle = styled('p')`
  font-size: 0.8rem;
  margin: 5px 0;
  text-transform: uppercase;
`;

export function cleanColorName(value) {
  return value.indexOf('(') !== -1
    ? value.substring(0, value.indexOf('('))
    : value;
}

//EVENT HANDLER
function handleEvent(
  setTooltip,
  show,
  variantHandler,
  value,
  option,
  secondOption
) {
  setTooltip(show);
  variantHandler.selectValue(value, option);
  if (secondOption)
    variantHandler.selectValue(secondOption.values[0], secondOption);
}

//CIRCLE COMPONENT
const ColorCircle = ({
  value,
  option,
  variantHandler,
  secondOption,
  combineSecond,
  ...rest
}) => {
  //USING STATE TO OPEN AND CLOSE TOOLTIP
  const [tooltip, setTooltip] = useState(false);
  return (
    <ColorCircleWrapper
      onMouseEnter={() =>
        handleEvent(
          setTooltip,
          true,
          variantHandler,
          value,
          option,
          secondOption
        )
      }
      onMouseLeave={() =>
        handleEvent(
          setTooltip,
          false,
          variantHandler,
          value,
          option,
          secondOption
        )
      }
      onClick={() => {
        handleEvent(
          setTooltip,
          true,
          variantHandler,
          value,
          option,
          secondOption
        );
      }}
      {...rest}
    >
      <Circle className={tooltip && 'selected'} value={value} />
      {tooltip && (
        <ToolTip className="tool-tip">
          <ToolTipText>{cleanColorName(value)}</ToolTipText>
          {combineSecond && secondOption && (
            <SecondToolTip
              secondOption={secondOption}
              variantHandler={variantHandler}
              onlyText={true}
            />
          )}
        </ToolTip>
      )}
      {tooltip && !combineSecond && secondOption && (
        <SecondToolTip
          secondOption={secondOption}
          variantHandler={variantHandler}
        />
      )}
    </ColorCircleWrapper>
  );
};

const SecondToolTip = ({ secondOption, variantHandler, onlyText = false }) => {
  const availableOptions = [];
  secondOption.values.map(size => {
    const validation = variantHandler.validateSelection(size, secondOption);
    if (validation === 'valid') {
      availableOptions.push(size);
    }
    return null;
  });
  if (availableOptions.length)
    if (onlyText)
      return <SecondOptionText availableOptions={availableOptions} />;
    else
      return (
        <ToolTip className="tool-tip bottom">
          <SecondOptionText availableOptions={availableOptions} />
        </ToolTip>
      );
  else return null;
};

const SecondOptionText = ({ availableOptions }) => {
  if (availableOptions.length)
    return (
      <>
        <ToolTipTitle>{t('Available sizes')}</ToolTipTitle>
        <ToolTipText>
          {availableOptions.map((value, index) => (
            <span key={index}>{value}</span>
          ))}
        </ToolTipText>
      </>
    );
  else return null;
};

//ALL CIRCLES COMPONENT
export const ColorCircles = ({
  product,
  variantHandler,
  colorOption,
  combineSecondTooltip = false
}) => {
  const secondOption = product.variants.options[1] || false;
  const colorAmount = 100;

  //SHOW ONLY FIRST {colorAmount} COLORS
  const moreColors =
    colorOption.values && colorOption.values.length > colorAmount;

  const manyColors = colorOption.values && colorOption.values.length > 4;

  if (colorOption) {
    return (
      <ColorCirclesWrapper
        data-color-count={colorOption.values.length}
        className={manyColors ? 'many-colors' : ''}
      >
        {colorOption.values.map((value, index) => {
          if (index <= colorAmount)
            return (
              <ColorCircle
                key={index}
                value={value}
                option={colorOption}
                secondOption={secondOption}
                variantHandler={variantHandler}
                combineSecond={combineSecondTooltip}
              />
            );
          return null;
        })}
        {moreColors && (
          <MoreColors>
            <ProductLink product={product}>
              <PlusIcon />
            </ProductLink>
          </MoreColors>
        )}
      </ColorCirclesWrapper>
    );
  } else return null;
};
