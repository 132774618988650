import React, { Component } from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import DataProvider from './Categories/CategoryProvider';
import TopBar from './Desktop/TopBar';
import DesktopHeader from './Desktop/DesktopHeader';
import MobileHeader from './Mobile/MobileHeader';
import MobileNav from './Mobile/MobileNav';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theming/Theme';

export const header = {
  height: {
    lg: theme.topBar.height.lg + theme.desktopHeader.height.lg,
    md: theme.topBar.height.md + theme.desktopHeader.height.md,
    sm: theme.mobileHeader.height.sm,
    xs: theme.mobileHeader.height.xs,
    scroll: theme.topBar.height.scroll + theme.desktopHeader.height.scroll
  },
  scrollBreak: 50
};

const DesktopHeaderWrapper = styled('header')`
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  font-weight: 400;
  a {
    text-decoration: none;
    &:hover {
    }
  }
  ${theme.below.sm} {
  }
`;

const DesktopHeaderSpacer = styled('div')`
  height: ${header.height.lg + 'px'};
  ${theme.only.md} {
    height: ${header.height.md + 'px'};
  }
`;

const MobileHeaderWrapper = styled('header')`
  position: fixed;
  z-index: 104;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  a {
    text-decoration: none;
    &:hover {
    }
  }
  ${theme.below.sm} {
  }
`;

const MobileHeaderSpacer = styled('div')`
  height: ${theme.header.height.sm + 'px'};
`;

class Header extends Component {
  state = {
    searchOpen: false,
    scrolling: false
  };

  setSearch = searchOpen => this.setState({ searchOpen });
  componentDidMount = function() {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = function() {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = event => {
    if (window.scrollY < header.scrollBreak && this.state.scrolling) {
      this.setState({ scrolling: false });
    } else if (window.scrollY > header.scrollBreak && !this.state.scrolling) {
      this.setState({ scrolling: true });
    }
  };

  render() {
    const { categoryTreeRoots } = this.props;
    const { searchOpen } = this.state;
    const { setSearch } = this;
    return (
      <React.Fragment>
        <Below breakpoint="xl">
          {matches =>
            matches ? (
              <div id="main-header">
                <MobileHeaderSpacer data-scrolling={this.state.scrolling} />
                <MobileHeaderWrapper>
                  <TopBar scrolling={this.state.scrolling} />
                  <MobileHeader
                    searchOpen={searchOpen}
                    setSearch={setSearch}
                    scrolling={this.state.scrolling}
                  />
                  <DrawerTarget id="menuDrawer" showCover={true}>
                    {({ isOpen, hideTarget }) => (
                      <MobileNav
                        categories={categoryTreeRoots}
                        onClick={hideTarget}
                        isOpen={isOpen}
                        hideTarget={hideTarget}
                      />
                    )}
                  </DrawerTarget>
                </MobileHeaderWrapper>
              </div>
            ) : (
              <div id="main-header">
                <DesktopHeaderSpacer data-scrolling={this.state.scrolling} />
                <DesktopHeaderWrapper>
                  <TopBar scrolling={this.state.scrolling} />
                  <DesktopHeader
                    scrolling={this.state.scrolling}
                    searchOpen={searchOpen}
                    setSearch={setSearch}
                    categoryTreeRoots={categoryTreeRoots}
                  />
                </DesktopHeaderWrapper>
              </div>
            )
          }
        </Below>
        <CartFlyout />
      </React.Fragment>
    );
  }
}

const HeaderComponent = () => (
  <DataProvider>{props => <Header {...props} />}</DataProvider>
);

export default HeaderComponent;
