import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theming/Theme';

const TrendButtonStyle = `
  font-family: ${theme.font.families.body};
  background-color: ${theme.colors.buy};
  border-color: ${theme.colors.buy};
  color: white;
  font-size: 1rem;
  border-radius: 0;
  >a, >a:active{
    color: white;
  }
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  ${theme.above.md} {
    max-width: 100%;
  }
  &:active {
    color: white;
  }
  :disabled {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  .secondary{
    border: 1px solid ${theme.colors.darkgrey};
    color: ${theme.colors.darkgrey};
    >a{
      color: ${theme.colors.darkgrey};
    }
    circle.path {
      stroke: black;
    }
  }
  
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
`;

export const TrendLink = styled(Link)`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

export const ButtonLinkExt = styled('a')`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <Button
      className={cx(
        props.className,
        props.secondary && 'secondary',
        props.buy && 'buy',
        props.cta && 'cta',
        props.hollow && 'hollow',
        props.list && 'list',
        props.low && 'low'
      )}
    >
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
