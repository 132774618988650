import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import SearchProduct from './SearchProduct';
import SearchFlyoutCategories from './SearchFlyoutCategories';
import { theme } from '../../../Theming/Theme';

export const SuggestedFlyout = styled('div')`
  position: absolute;
  left: 5px;
  right: 0;
  top: 60px;
  background: white;
  padding: 1rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07);
  z-index: 1;
  max-width: 500px;
  max-height: 400px;
  overflow: auto;
  li {
    border-bottom: 1px solid ${theme.colors.lightgrey};
  }
`;

const SearchFlyoutProducts = styled('div')``;

export const FlyoutTitle = styled('h4')`
  border-bottom: 1px solid ${theme.colors.border};
  margin-bottom: 0.3rem;
  padding-bottom: 0.3rem;
  color: ${theme.colors.greys[4]};
`;

const ProductList = styled('ul')``;

const SearchFlyout = searchProps => {
  const products =
    (searchProps.result &&
      searchProps.result.products &&
      searchProps.result.products.result) ||
    [];
  return (
    <FlyoutTarget id="searchFlyout">
      {flyoutProps =>
        searchProps.isOpen &&
        flyoutProps.isOpen &&
        searchProps.isDirty && (
          <div {...searchProps.getFlyoutProps()}>
            <SuggestedFlyout>
              <SearchFlyoutCategories {...searchProps} />
              <SearchFlyoutProducts>
                {searchProps.loading ? (
                  t('Loading…')
                ) : products.length > 0 ? (
                  <Fragment>
                    <FlyoutTitle>{t('Products')}</FlyoutTitle>
                    <ProductList>
                      {products.map(product => (
                        <SearchProduct
                          key={product.id}
                          product={product}
                          onClose={searchProps.closeSearch}
                          term={searchProps.term}
                        />
                      ))}
                    </ProductList>
                  </Fragment>
                ) : (
                  t('Search to find products')
                )}
              </SearchFlyoutProducts>
            </SuggestedFlyout>
          </div>
        )
      }
    </FlyoutTarget>
  );
};

export default SearchFlyout;
