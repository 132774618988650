import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theme';

const ToolTipWrapper = styled('div')`
  position: absolute;
  font-family: ${theme.font.families.content};
  left: 50%;
  background: #333;
  color: white;
  text-align: center;
  padding: 0 0.5rem;
  width: 120px;
  transform: translateX(-50%) translateY(0);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  &:not(.bottom) {
    bottom: 34px;
  }
  &.bottom {
    top: 34px;
  }

  //TRIANGLE
  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    position: absolute;
  }

  &:not(.bottom):after {
    border-top: 8px solid #333;
    bottom: -8px;
  }
  &.bottom:after {
    border-bottom: 8px solid #333;
    top: -8px;
  }

  ${theme.above.md} {
    width: 170px;
  }
`;

export const ToolTip = props => {
  return <ToolTipWrapper {...props} />;
};
