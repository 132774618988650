import React from 'react';
import { ColorCircles } from './ColorCircles';
import { StandardVariant } from './StandardVariant';

export const VariantHandler = ({
  product,
  variantHandler,
  combineSecondTooltip
}) => {
  const colorOptionArray = product.variants.options.filter(x =>
    x?.name?.includes('Farge')
  );
  const colorOption = colorOptionArray.length && colorOptionArray[0];

  return colorOption ? (
    <ColorCircles
      variantHandler={variantHandler}
      product={product}
      colorOption={colorOption}
      combineSecondTooltip={combineSecondTooltip}
    />
  ) : (
    <StandardVariant variantHandler={variantHandler} product={product} />
  );
};
