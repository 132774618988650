import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theming/Theme';
import { Price } from '@jetshop/ui/Price';
import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import DecrementQuantity from '@jetshop/core/components/Mutation/DecrementQuantity';
import IncrementQuantity from '@jetshop/core/components/Mutation/IncrementQuantity';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import CloseButton from '../ui/CloseButton';
import { Icon } from 'react-icons-kit';
import { iosPlusEmpty } from 'react-icons-kit/ionicons/iosPlusEmpty';
import { iosMinusEmpty } from 'react-icons-kit/ionicons/iosMinusEmpty';
import ProductLink from '@jetshop/ui/ProductLink';
import cartQuery from './CartQuery.gql';
import { PriceFormatter } from '../ui/PriceFormatter';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${theme.colors.border};
`;

const ProductName = styled('h2')`
  color: #555;
  font-size: 1rem;
  font-family: ${theme.font.families.body};
  font-weight: normal;
`;

const ProductVariant = styled('h3')`
  color: #828282;
  font-size: 0.85rem;
  margin-top: 5px;
  font-family: ${theme.font.families.body};
  font-weight: normal;
`;

const ProductImage = styled('div')`
  width: 25%;
`;

const ProductDetail = styled('section')`
  width: 75%;
  padding-left: 1rem;
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-family: ${theme.font.families.heavy};
  text-align: right;
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: #f2f2f2;
    color: black;
    height: 20px;
    width: 20px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
    svg {
      fill: black;
    }
    i {
      display: flex !important;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
    line-height: 20px;
  }
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    display: inline-block;
    position: relative;
    top: -2px;
    padding: 3px;
    margin: -3px;
  }
  svg {
    color: #999;
  }
`;
const PriceContent = styled('div')`
  font-size: 1.2rem;
  color: ${theme.colors.black};
`;
const PriceWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const CartItem = ({ item, className = '', close }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);
  return (
    <Wrapper className={className}>
      <ProductImage>
        {item.product.images.length > 0 && (
          <ProductLink product={item.product} onClick={close}>
            <Image
              aspect="1:1"
              crop={false}
              sizes={[200]}
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
            />
          </ProductLink>
        )}
      </ProductImage>
      <ProductDetail>
        <ItemDetails>
          <ProductLink product={item.product} onClick={close}>
            <ProductName>{item.product.name}</ProductName>
            {isVariant && (
              <ProductVariant>{variantValues.join(', ')}</ProductVariant>
            )}
          </ProductLink>
        </ItemDetails>

        <RemoveItem>
          <RemoveFromCart cartQuery={cartQuery}>
            {removeFromCart => (
              <CloseButton
                onClick={() => removeFromCart(item.id, item.product)}
                size={24}
              />
            )}
          </RemoveFromCart>
        </RemoveItem>

        <AdjustQty>
          <DecrementQuantity cartQuery={cartQuery}>
            {decrementQuantity => (
              <button
                disabled={item.quantity === 1}
                onClick={() =>
                  item.quantity !== 1 && decrementQuantity(item.id)
                }
              >
                <Icon icon={iosMinusEmpty} />
              </button>
            )}
          </DecrementQuantity>
          <span>{item.quantity}</span>
          <IncrementQuantity cartQuery={cartQuery}>
            {incrementQuantity => (
              <button onClick={() => incrementQuantity(item.id)}>
                <Icon icon={iosPlusEmpty} />
              </button>
            )}
          </IncrementQuantity>
        </AdjustQty>

        {item.total == null ? (
          <LinePrice price={item.total} />
        ) : (
          <PriceWrapper>
            <PriceContent>
              <PriceFormatter
                price={item.total}
                previousPrice={item.previousTotal}
                code={',-'}
                culture={'nb-NO'}
              />
            </PriceContent>
          </PriceWrapper>
        )}
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
