import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import SiteContent from '../../../Util/SiteContent';
import { theme } from '../../../Theming/Theme';

const PartnerLogosWrapper = styled('div')`
  margin: 2rem 0;
  display: flex;
  padding: 0 2rem;
  justify-content: space-evenly;
  > div {
    width: 50px;
    display: flex;
    margin: 0 1rem;
    &:last-child {
      ${theme.above.md} {
        width: 140px;
      }
    }
  }
`;

const PartnerLogos = () => {
  const sizes = [200];
  return (
    <SiteContent names={['PartnerLogos']}>
      {items => {
        if (items.length > 0) {
          const item = items[0];
          return (
            <PartnerLogosWrapper>
              {item &&
                item.content &&
                Array.isArray(item.content) &&
                item.content.map((imageUrl, index) => (
                  <div key={index}>
                    <Image src={imageUrl} sizes={sizes} />
                  </div>
                ))}
            </PartnerLogosWrapper>
          );
        } else return null;
      }}
    </SiteContent>
  );
};

export default PartnerLogos;
