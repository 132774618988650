import React, { useContext, useRef, useState } from 'react';
import { styled } from 'linaria/react';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import { theme } from '../../../Theming/Theme';
import { cleanCategories } from '../Categories/CategoryProvider';
import { Query } from 'react-apollo';
import deepCategoriesQuery from '../Categories/DeepCategoriesQuery.gql';
import { ExtraMenuLinks } from '../Desktop/ExtraMenuLinks';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import CategoryLink from '@jetshop/ui/CategoryLink';

const CategoryNoSubs = styled('span')`
  display: flex;
  width: 85%;
`;

const CategoryNoSubsWrapper = styled('span')`
  display: flex;
  width: 100%;
  align-items: center;
`;

const IconWrapper = styled('div')`
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-size: cover;

  &.level2 {
    padding: 0.25rem 0;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

const ImageWrapper = styled('div')`
  &.image-failed {
    display: none;
  }
`;

const Carrot = styled('div')`
  margin-left: auto;
  margin-right: 0;
`;

const BackCarrot = styled('div')`
  margin-left: 0;
  transform: rotate(-180deg);
`;

const BackWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const CategoryWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  font-size: 1.2rem;
  font-family: ${theme.font.families.heavy};
  line-height: 3em;
  border-top: 2px solid #eee;
  border-bottom: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.white};
  color: inherit;

  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${StyledCarrot} {
    transform: rotate(-90deg);
    margin-right: 0px;
  }
`;

function goToCat(hideTarget) {
  setTimeout(function() {
    hideTarget();
  }, 0);
}

const IconImage = ({ index, level, categoryId }) => {
  const ref = useRef();
  const { selectedChannel } = useContext(ChannelContext);
  const baseUrl = selectedChannel?.url;
  if (level >= 3) return null;

  const src = `${baseUrl}/pub_docs/files/icons/category-${categoryId}.svg`;

  return (
    <IconWrapper
      className={level >= 2 ? 'level2' : undefined}
      style={{ backgroundImage: `url(${src})` }}
    ></IconWrapper>
  );
};

// This is a small hack to swap out the wrapper for a category if it doesn't have subcategories
// It's because <Fragment /> doesn't accept additional props without warnings, such as "category".
const Div = ({ category, children }) => {
  return <div>{children}</div>;
};

const Category = ({
  index,
  title,
  category,
  icon,
  directLink,
  categoryHistory,
  setCategoryHistory,
  hideTarget
}) => {
  const hasSubcategories = category.hasSubcategories;
  const isCampaignCategory =
    category?.name === 'Kampanjer' || category?.id === 3178;
  const noLink = !isCampaignCategory && !directLink && hasSubcategories;
  const Wrapper = noLink ? Div : CategoryLink;

  return (
    <Wrapper category={category}>
      <CategoryWrapper
        as="button"
        onClick={() => {
          if (noLink) {
            const newCategories = [...categoryHistory, category];
            return setCategoryHistory(newCategories);
          }

          goToCat(hideTarget);
        }}
      >
        {icon ? (
          <IconImage
            index={index}
            level={category.level}
            categoryId={category.id}
          />
        ) : null}
        <span>{title || category.name}</span>
        {noLink ? (
          <Carrot>
            <StyledCarrot />
          </Carrot>
        ) : null}
      </CategoryWrapper>
    </Wrapper>
  );
};

const Categories = ({ categories, icon, hideTarget }) => {
  const [categoryHistory, setCategoryHistory] = useState([]);
  const historyLength = categoryHistory.length;
  const activeCategory = categoryHistory[historyLength - 1];

  const renderCategories =
    historyLength > 0 ? activeCategory?.subcategories : categories;

  return (
    <div>
      {historyLength > 0 ? (
        <CategoryWrapper
          as="button"
          onClick={() => {
            if (historyLength > 0) {
              const newCategories = categoryHistory.slice(0, -1);
              return setCategoryHistory(newCategories);
            }
          }}
        >
          <BackWrapper>
            <BackCarrot>
              <StyledCarrot />
            </BackCarrot>
            <span>
              {historyLength === 1
                ? t('Back')
                : t('Back to { categoryName }', {
                    categoryName:
                      categoryHistory[historyLength - 2]?.name || '...'
                  })}
            </span>
          </BackWrapper>
        </CategoryWrapper>
      ) : null}
      {activeCategory ? (
        <Category
          icon={false}
          title={t('Show all')}
          directLink={true}
          category={activeCategory}
          categoryHistory={categoryHistory}
          setCategoryHistory={setCategoryHistory}
          hideTarget={hideTarget}
        />
      ) : null}
      {renderCategories
        ?.filter(category => category?.name?.indexOf('=====') === -1)
        ?.map((category, index) => {
          return (
            <Category
              key={category.id}
              index={index}
              directLink={false}
              category={category}
              categoryHistory={categoryHistory}
              setCategoryHistory={setCategoryHistory}
              icon={icon}
              hideTarget={hideTarget}
            />
          );
        })}
    </div>
  );
};

const ExtraLink = ({ children, icon }) => {
  return (
    <CategoryWrapper>
      <CategoryNoSubsWrapper>
        {icon ? <IconImage index={999} /> : null}
        <CategoryNoSubs>{children}</CategoryNoSubs>
      </CategoryNoSubsWrapper>
    </CategoryWrapper>
  );
};

const MobileCategoriesComp = ({ categories, hideTarget, isOpen }) => {
  const [open, setOpen] = useState(false);
  const channelTwo = useContext(ChannelContext)?.selectedChannel?.id === 2;

  if (isOpen && !open) {
    setOpen(true);
  }

  if (open) {
    return (
      <Query variables={{ levels: 1 }} query={deepCategoriesQuery}>
        {result => {
          const { data } = result;
          const fullCategories =
            (data && cleanCategories(data.categories)) || categories;
          if (categories) {
            return (
              <div>
                <Categories
                  categories={fullCategories}
                  hideTarget={hideTarget}
                  icon={!channelTwo}
                />
                <ExtraMenuLinks tag={ExtraLink} />
              </div>
            );
          } else return null;
        }}
      </Query>
    );
  }

  if (categories && categories.length > 0) {
    return (
      <div>
        <Categories
          categories={categories}
          hideTarget={hideTarget}
          icon={!channelTwo}
        />
        <ExtraMenuLinks tag={ExtraLink} icon={!channelTwo} />
      </div>
    );
  }

  return null;
};

export default MobileCategoriesComp;
