//Hekta på turs standardfärger
export function standardColors(value) {
  var lowercaseValue = value.toLowerCase();
  const colors = {
    hvit: '#FFFFFF',
    white: '#FFFFFF',
    sort: '#000000',
    black: '#000000',
    svart: '#000000',
    red: '#ff0000',
    rød: '#ff0000',
    blue: '#0000ff',
    blå: '#0000ff',
    navy: '#000080',
    marine: '#000080',
    Silver: '#C0C0C0',
    silver: '#C0C0C0',
    sølv: '#C0C0C0',
    grå: '#808080',
    grey: '#808080',
    yellow: '#FFFF00',
    gul: '#FFFF00',
    olive: '#808000',
    oliven: '#808000',
    lime: '#00FF00',
    green: '#008000',
    grønn: '#008000',
    pink: '#FF00FF',
    rosa: '#FF00FF',
    purple: '#800080',
    lilla: '#800080',
    brown: '#8B4513',
    brun: '#8B4513',
    orange: '#f17a00',
    'light gray': '#D3D3D3',
    'light grey': '#D3D3D3',
    lysegrå: '#D3D3D3',
    petrol: '#196585'
  };

  if (colors[lowercaseValue]) return colors[lowercaseValue];
  else {
    console.log(lowercaseValue + ' has no hex-color');
    return '#888';
  }
}
