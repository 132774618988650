import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkmarkRound } from 'react-icons-kit/ionicons/checkmarkRound';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { faShippingFast } from '@fortawesome/pro-light-svg-icons';
import { faUndo } from '@fortawesome/pro-light-svg-icons';
import { faBoxHeart } from '@fortawesome/pro-light-svg-icons';
import SiteContent from '../../../Util/SiteContent';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theme';
import { Link } from 'react-router-dom';

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 10px;
  ${theme.below.md} {
    margin-right: 6px;
  }
`;

const ListItem = styled('li')`
  font-size: 12px;
  display: flex;
  align-items: center;
  > a,
  > span {
    display: flex;
    align-items: center;
  }
`;

function getIcon(index) {
  switch (index) {
    case 0:
      return faShippingFast;
    case 1:
      return faBoxHeart;
    case 2:
      return faUndo;
    case 3:
      return faCreditCard;
    default:
      return checkmarkRound;
  }
}

const TopBarUsp = () => {
  return (
    <SiteContent names={['TopBarUsp']}>
      {items => {
        const item = items[0];
        return (
          <Fragment>
            {item && item.content.list && (
              <ul className="top-bar-usp-list">
                {item.content.list &&
                  item.content.list.map((listItem, index) => (
                    <ListItem key={index}>
                      {typeof listItem == 'object' && listItem.link ? (
                        <Link to={listItem.link}>
                          <StyledIcon icon={getIcon(index)} />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: listItem.text ? listItem.text : listItem
                            }}
                          />
                        </Link>
                      ) : (
                        <span>
                          <StyledIcon icon={getIcon(index)} />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: listItem.text ? listItem.text : listItem
                            }}
                          />
                        </span>
                      )}
                    </ListItem>
                  ))}
              </ul>
            )}
          </Fragment>
        );
      }}
    </SiteContent>
  );
};

export default TopBarUsp;
