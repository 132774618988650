import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import FooterLowerContent, { LowerContentWrapper } from './FooterLowerContent';
import { theme } from '../../Theming/Theme';
import NewsletterBox from './NewsletterBox';
import { ManyIconsBar } from '../../StartPage/IconBar';
import Image from '@jetshop/ui/Image';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const FooterWrapper = styled('footer')`
  color: ${theme.footer.color};
  background: #424b3e;
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    background-size: cover;
    a {
    }
  }
  .footer-lower-wrapper {
    width: 100%;
    padding: 10px 0;
    ${theme.above.xl} {
      padding-right: 30%;
    }
    ${theme.below.sm} {
      padding-right: 15%;
    }
    padding-right: 37%;
    padding-left: 10%;
    background-size: cover;
    a {
    }
    ${LowerContentWrapper} {
      padding-top: 0.5rem;
      margin-bottom: 3rem;
      p:first-child {
        display: inline-block;
        color: ${theme.colors.greys[3]};
        ${theme.below.md} {
          display: none;
        }
      }
      .jetshop-text {
        ${theme.above.md} {
          float: right;
        }
        text-align: center;
      }
    }
  }
`;
const LogoWrapper = styled('div')`
  right: 0;
  width: 230px;
  float: right;
  position: absolute;
  ${theme.above.md} {
    margin-right: 190px;
  }
  ${theme.below.md} {
    width: 170px;
  }
  ${theme.below.sm} {
    width: 100px;
  }
`;
const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  text-align: left;
  padding: 0 ${theme.general.pagePadding.md};
  ${theme.above.md} {
    padding: 0 ${theme.general.pagePadding.lg};
  }
`;

const Footer = () => {
  const channelTwo = useContext(ChannelContext).selectedChannel.id === 2;
  return (
    <FooterWrapper>
      <div>
        <ManyIconsBar />
      </div>
      <div className="newsletter-box ">
        <NewsletterBox />
      </div>
      <Wrapper>
        <div className="footer-lower-wrapper">
          <div>
            <FooterLowerContent />
          </div>
        </div>
        <LogoWrapper>
          <Image
            sizes={[400]}
            src={
              channelTwo
                ? '/pub_images/original/logo-footer.png'
                : '/pub_images/original/logo_hpt_rod_4877.png'
            }
            aspect="4:3"
          />
        </LogoWrapper>
      </Wrapper>
    </FooterWrapper>
  );
};

export default Footer;
