import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import GoogleFont from '@jetshop/core/components/Fonts/GoogleFont';
import TypekitFont from '@jetshop/core/components/Fonts/TypekitFont';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { KlaviyoTracking } from './Util/KlaviyoTracking';
import { config } from '../shop.custom.config';
import './Theming/GlobalStyles';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const Blog = loadable(() => import('./WordPress/Blog'), {
  fallback: <LoadingPage />
});
const Post = loadable(() => import('./WordPress/Post'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <GenericError>
      <KlaviyoTracking>
        <ModalProvider>
          <Container>
            <LoadingBar />
            {selectedChannel.id === 1 ? (
              <GoogleFont
                primaryFont="Josefin+Sans:300,400,700"
                secondaryFonts={['Open+Sans:400,700']}
              />
            ) : (
              <TypekitFont primaryFont="justus-pro" id="vkb5rke" />
            )}

            <Helmet
              titleTemplate={
                selectedChannel.id === 2
                  ? '%s - Hekta På Jakt'
                  : '%s - Hekta På Tur'
              }
              defaultTitle={
                selectedChannel.id === 2 ? 'Hekta På Jakt' : 'Hekta På Tur'
              }
              link={[
                {
                  rel: 'icon',
                  type: 'image/png',
                  href:
                    selectedChannel.id === 2
                      ? '/favicon-hj.ico'
                      : '/favicon.ico'
                }
              ]}
            />
            <ProductListProvider queries={productListQueries}>
              <PaginationProvider defaultProductsPerPage={24}>
                <Header />
                <Content>
                  <Switch>
                    <Route exact path="/" component={LoadableStartPage} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/blog/:category" component={Blog} />
                    <Route
                      exact
                      path="/blog/:category/:slug"
                      component={Post}
                    />
                    <Route
                      path={config.routes.favourites.path}
                      component={Favourites}
                    />
                    <Route
                      path={routes.search.path}
                      component={LoadableSearchPage}
                    />
                    <Route path={routes.stores.path} component={StoreLocator} />
                    <Route
                      path={`${routes.store.path}/:id`}
                      component={Store}
                    />
                    <Route
                      path="/preview"
                      render={props => (
                        <PreviewRoute
                          productPage={LoadableProductPage}
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          categoryPage={LoadableCategoryPage}
                          StartPage={LoadableStartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={LoadableProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </Content>
                <Footer />
              </PaginationProvider>
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={[
                'sortOrderChange',
                'filterChange',
                'paginationChange'
              ]}
            />
          </Container>
        </ModalProvider>
      </KlaviyoTracking>
    </GenericError>
  );
}

export default Shop;
