import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theme';

const PercentWrapper = styled('div')`
  font-size: 1.2rem !important;
  font-family: ${theme.font.families.heavy};
  text-align: center;
  vertical-align: middle;

  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  margin-right: 10px;

  ${theme.below.sm} {
    font-size: 1rem;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
`;

export const PercentPrice = props => {
  if (props.previousPrice && props.previousPrice.incVat > props.price.incVat) {
    const percent = Math.round(
      ((props.previousPrice.incVat - props.price.incVat) /
        props.previousPrice.incVat) *
        100
    );
    if (percent > 15) {
      return <PercentWrapper>-{percent}%</PercentWrapper>;
    }
  }
  return null;
};
