import React from 'react';
import { styled } from 'linaria/react';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';

export const Input = styled('input')`
  background: #fff;
  border: none;
  box-shadow: 0;
  height: 42px;
  padding: 0 1rem;
  width: 100%;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const SearchInput = ({ getInputProps }) => (
  <FlyoutTrigger id="searchFlyout" showCover={false}>
    {({ showTarget }) => (
      <Input
        placeholder="Hei! Hva leter du etter?"
        type="search"
        {...getInputProps({
          onFocus: showTarget,
          refKey: 'innerRef'
        })}
      />
    )}
  </FlyoutTrigger>
);

export default SearchInput;
