import React from 'react';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theme';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Carot } from '../../../svg/Carrot.svg';
import { VariantHeading } from './VariantButtons';

export const DropdownWrapper = styled('div')`
  margin-bottom: 1rem;
  ${VariantHeading} {
    margin-top: 1rem;
  }
  [data-flight-dropdown] {
    background: white;
    [data-flight-dropdown-button] {
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      height: 54px;
      border: 1px solid ${theme.colors.borderInput};
      border-radius: 0 !important;
      justify-content: space-between;
      svg {
        transition: all 200ms;
      }
    }
    &[data-flight-dropdown-open='true'] {
      [data-flight-dropdown-button] {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    [data-flight-dropdown-items] {
      min-width: 100%;
      border: 1px solid ${theme.colors.borderInput};
      margin-top: -1px;
    }
    [data-flight-dropdown-item] {
      display: flex;
      align-items: center;
      border-top: 1px solid ${theme.colors.borderLight};
      padding: 1rem 0.5rem;
      &:hover {
        background: ${theme.colors.greys[0]};
      }
      &:focus,
      &:hover {
        outline: none;
      }
      &.selected {
        background: ${theme.colors.greys[1]};
        p {
          font-weight: ${theme.font.weights.bold};
        }
      }
    }
  }
  &.invalid [data-flight-dropdown-button] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const VariantWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const VariantInfo = styled('div')`
  display: flex;
  align-items: center;
`;

export const VariantImage = styled('div')`
  width: 60px;
`;

export const VariantName = styled('p')`
  margin-left: 15px;
  margin-right: 10px;
  font-size: 1rem;
  ${theme.below.sm} {
    font-size: 0.9rem;
  }
`;

export const VariantStock = styled('div')`
  color: ${theme.colors.outOfStock};
  &.buyable {
    color: ${theme.colors.inStock};
  }
  font-size: 1rem;
  ${theme.below.sm} {
    font-size: 0.9rem;
  }
`;

function fixValueWithHexCode(value) {
  if (value && value.includes('(')) {
    return value.substring(0, value.indexOf('('));
  }
  return value;
}

const VariantDropdown = ({
  option,
  variantHandler,
  showValidationMessage,
  showName = false
}) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection
  } = variantHandler;
  const selectedValue = getSelectedValue(option);
  return (
    <DropdownWrapper className={showValidationMessage && 'invalid'}>
      {showName && <VariantHeading>{option.name}</VariantHeading>}
      <DropdownMenu>
        <DropdownMenuButton id={`dropdown-option-${option.name}`}>
          {selectedValue ? fixValueWithHexCode(selectedValue) : t('Choose')}
          <Carot />
        </DropdownMenuButton>
        <DropdownMenuItems>
          {option.values.map(value => {
            const validation = validateSelection(value, option);
            const variant = getVariantForSelection(value, option);
            return (
              <div key={value + option.name}>
                {validation !== 'invalid' ? (
                  <DropdownMenuItem
                    className={selectedValue === value && 'selected'}
                    disabled={validation === 'invalid'}
                    onSelect={({ setIsOpen }) => {
                      selectValue(value, option);
                      setIsOpen(false);
                    }}
                    style={{ opacity: validation === 'invalid' ? 0.5 : 1 }}
                  >
                    <VariantWrapper>
                      <VariantInfo>
                        <VariantName>{fixValueWithHexCode(value)}</VariantName>
                      </VariantInfo>
                      {variant !== undefined ? (
                        <VariantStock
                          className={variant.stockStatus.buyable && 'buyable'}
                        >
                          {variant.stockStatus.text}
                        </VariantStock>
                      ) : (
                        <span></span>
                      )}
                    </VariantWrapper>
                  </DropdownMenuItem>
                ) : (
                  <span></span>
                )}
              </div>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default VariantDropdown;
