import React, { Fragment, useContext } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePaddingWrapper } from '../../MaxWidth';
import MaxWidth from '../../../Layout/MaxWidth';
import Logo from '../Logo';
import DesktopNav from './DesktopNav';
import { theme } from '../../../Theming/Theme';
import Image from '@jetshop/ui/Image';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import InfoIcon from '../../../ui/icons/InfoIcon';

const DesktopHeaderWrapper = styled('div')`
  height: ${theme.desktopHeader.height.lg + 'px'};
  //transition: height 0.6s ease;
  padding: 0 0;
  border-bottom: 1px solid ${theme.colors.border};
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.height.lg - 20 + 'px'};
  }
  ${PagePaddingWrapper},
  ${Inner} {
    height: 100%;
  }
  ${Inner} {
    display: flex;
    align-items: center;
  }

  .top-nav-list {
    display: flex;
    white-space: nowrap;
    ${theme.only.lg} {
      justify-content: left;
    }
    ${theme.only.md} {
      justify-content: center;
    }
    align-items: center;
    bottom: 0;
    position: inherit;
    padding-top: 20px;
    padding-left: 65px;
    > li {
      display: flex;
      &:last-child {
        padding-right: 1.2rem !important;
      }
    }
  }
  .header-icon {
    font-size: 26px;
  }
  .header-button {
    position: relative;
    .number-badge {
      right: -5px;
      bottom: 19px;
      top: auto;
    }
  }
`;

const StoreLogo = styled('div')`
  position: absolute;
  overflow: hidden;
  transition: height 0.6s ease;
  width: 110px;
  transition: width 0.6s ease;
  margin-left: 10px;
  //margin: 0 auto;
  float: left;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'}};
  }
  @media screen and (max-width: 1600px) {
    top: 0;
    transform: scale(0.7);
  }
  text-align: center;
  text-decoration: none;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
    > img,
    > div,
    > svg {
      transition: max-height 0.6s ease;
      max-width: 100%;
      ${theme.only.md} {
      }
    }
  }
  &[data-scrolling='true'] {
    max-width: 6%;
    a {
      > img,
      > div,
      > svg,
      > .scroll-logo {
      }
    }
    .scroll-logo {
    }
  }
`;

export const HeaderButton = styled('button')`
  display: flex;
`;

export const HeaderIconText = styled('span')`
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: ${theme.font.weights.normal};
  ${theme.below.lg} {
    display: none;
  }
`;

const SearchFieldWrapper = styled('div')`
  border: 1px solid grey;
  margin-top: 13px;
  height: 150%;
  width: 500px;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  height: 50%;
  justify-content: space-between;
  &.second-design {
    .search {
      order: 2;
      width: 40%;
      > * {
        width: 100%;
        > * {
          width: 100%;
        }
      }
    }
    .image {
      order: 1;
      width: 27%;
    }
    .buttons {
      order: 3;
      width: 33%;
      justify-content: flex-end;
    }
  }
`;
const Column = styled('div')`
  display: flex;
  flex-direction: column;
  top: 0;
  min-width: 100px;
  &.buttons {
    flex-direction: row;
    min-width: 0px;
    > * {
      align-items: center;
      display: flex;
    }
    > a {
      margin-right: 1.5rem;
    }
    .info-icon {
      font-size: 2rem;
    }
  }
`;
const ImageSize = styled('div')`
  width: 200px;
`;
const StyledMaxWidth = styled(MaxWidth)`
  @media screen and (max-width: 1700px) {
    margin-left: 8%;
    width: 92%;
  }
`;
const StyledNavMaxWidth = styled(MaxWidth)`
  position: inherit;
  @media screen and (max-width: 1600px) {
    margin-left: 0;
    width: 100%;
  }
`;
const CenterImageHelper = styled('span')`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;

const DesktopHeader = ({
  scrolling,
  searchOpen,
  setSearch = true,
  categoryTreeRoots
}) => {
  const channelTwo = useContext(ChannelContext).selectedChannel.id === 2;
  return (
    <Fragment>
      <DesktopHeaderWrapper data-scrolling={scrolling}>
        <StoreLogo data-scrolling={scrolling}>
          <Link to="/">
            {!scrolling && <CenterImageHelper />}
            <Logo type="desktop" />
          </Link>
        </StoreLogo>
        <StyledMaxWidth>
          <Row className={`${channelTwo ? 'second-design' : ''}`}>
            <Column className="search">
              <Row>
                <SearchFieldWrapper>
                  <SearchField onCancel={() => setSearch(false)} />
                </SearchFieldWrapper>
              </Row>
            </Column>
            <Column className="image">
              <ImageSize>
                <Image
                  src="/pub_images/original/arets-logistik-2x.png"
                  aspect="3:1"
                  sizes={['300px']}
                />
              </ImageSize>
            </Column>
            <Column className="buttons">
              <Link to={channelTwo ? '/kontakt-oss-hpj' : '/kontakt'}>
                <InfoIcon className="info-icon" />
              </Link>
              <FavouriteCount className="header-button" />
              <CartButton />
            </Column>
          </Row>
        </StyledMaxWidth>
        <StyledNavMaxWidth>
          <DesktopNav
            scrolling={scrolling}
            categoryTreeRoots={categoryTreeRoots}
          />
        </StyledNavMaxWidth>
      </DesktopHeaderWrapper>
    </Fragment>
  );
};

export default DesktopHeader;
