import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theme';
import { Link } from 'react-router-dom';
import SiteContent from '../../Util/SiteContent';
import { useLocation } from 'react-router';

const animationLength = 800;

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
  padding: 7px 2rem;
  text-align: center;
  background-color: ${theme.colors.secondary};
  max-width: 100%;
  opacity: 0;
  animation-name: enter;
  animation-duration: ${animationLength}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 50;
  &,
  > a {
    width: 100%;
    color: inherit;
  }
  > a,
  > button svg,
  > a:active {
    color: inherit;
    fill: inherit;
  }
  &.unmounting {
    animation-name: exit;
  }
  button {
    position: absolute;
    right: 10px;
    top: 50%;
    padding: 5px;
    margin: -5px;
    margin-top: -12px;
    svg {
    }
  }
  p {
    margin: 0;
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes exit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export function isHexCode(code, backup) {
  if (!code) return backup;

  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return reg.test(code) ? code : backup;
}

const CampaignBar = ({ item }) => {
  const data = item.content;
  const location = useLocation();
  const path = location.pathname;

  const backgroundColor = isHexCode(data.background, '#333');
  const color = isHexCode(data.color, '#FFF');
  const campaignBarLink = data.link;
  const campaignBarHtml = data.text;
  const disableUrls = data.disableUrls;

  if (disableUrls && disableUrls.length && disableUrls.includes(path))
    return null;

  return item.isJson && campaignBarHtml ? (
    <Wrapper style={{ background: backgroundColor, color, fill: color }}>
      {campaignBarLink ? (
        <Link to={campaignBarLink}>{campaignBarHtml}</Link>
      ) : (
        <>{campaignBarHtml}</>
      )}
    </Wrapper>
  ) : null;
};

const CampaignBarComp = () => (
  <SiteContent names={['CampaignBar']}>
    {(items, renderLink) => {
      const item = items[0];
      return item ? <CampaignBar item={item} renderLink={renderLink} /> : null;
    }}
  </SiteContent>
);

export default CampaignBarComp;
