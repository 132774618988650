import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';
import { config } from '../../shop.custom.config';
import { createBreakPoints } from './helpers';

/** 
    To define your own breakpoints, you can edit the breakpoint array below.
    The index of the breakpoint corresponds to the label. So, in this case, xs is at 20rem, sm is at 40rem, ect.
    You can add additional breakpoints or change the values of the ones that are currently there however it is important you keep the four existing labels as we use them for some components in @jetshop/ui 
. */

const labels = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
const breakpoints = ['0px', '20rem', '50rem', '70rem', '80rem', '100rem'];

export const mediaQueries = createBreakPoints(labels, breakpoints);
const cssMediaQueries = createBreakPoints(labels, breakpoints, true);

const channelTwo = config.channelId === 2;

console.log('CHANNEL_ID', config.channelId);
console.log('channelTwo', channelTwo);

const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '48px',
  '64px',
  '72px'
];
const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px'
];
const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 700
};

export const fonts = {
  primary: 'Source Sans Pro'
};

export const font = {
  families: !channelTwo
    ? {
        body: 'Josefin Sans, sans-serif',
        heavy: 'Josefin Sans, sans-serif',
        content: 'Open sans, sans-serif'
      }
    : {
        body: 'justus-pro, sans-serif',
        heavy: 'justus-pro, sans-serif',
        content: 'justus-pro, sans-serif'
      },
  weights: {
    light: 300,
    normal: 400,
    bold: 700
  },
  size: '14px',
  paragraph: {
    fontSize: '1rem',
    lineHeight: '1.7em',
    marginBottom: '1rem'
  },
  header: {
    textTransform: 'none',
    fontSize: [
      '__',
      ['3rem', '2.2rem'],
      ['2.4rem', '2rem'],
      ['1.8rem', '1.4rem'],
      ['1.4rem', '1.2rem'],
      ['1rem', '1rem'],
      ['0.875rem', '0.875rem']
    ],
    reduction: '10%',
    letterSpacing: '1px'
  }
};

const colors = {
  black: '#000',
  white: '#FFF',
  red: '#E82A2A',
  font: '#222',
  primary: channelTwo ? '#4E4A3C' : '#df191e',
  primaryLight: '#ff4a4e',
  secondary: '#222',
  green: channelTwo ? '#4E4A3C' : '#424b3e',
  discount: '#b44e3c',
  link: '#ce3c8a',
  border: '#DDD',
  borderLight: '#F5F5F5',
  borderDark: '#333',
  borderInput: '#DDD',
  lightgrey: '#f6f6f6',
  mineralGreen: '#8e989a',
  terracotta: '#f8ddcc',
  powderpink: '#e1c6be',
  vanillaWhite: '#fbf8f4',
  grey: '#565558',
  lightPinkBeige: '#f4e9df',
  mintGreen: '#dae0de',
  error: '#ff3131',
  success: '#69a24c',
  tablegrey: '#DDD',
  greys: [
    '#F9F9F9',
    '#F1F1F1',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#3B3B3B',
    '#222'
  ],
  buy: channelTwo ? '#4E4A3C' : '#538041',
  inStock: '#339600',
  outOfStock: '#bf8418',
  notifyWhenBack: '#444'
};

const general = {
  maxWidth: '1500px',
  pagePadding: {
    lg: '1rem',
    md: '1rem',
    sm: '1rem'
  },
  hallmarkBorder: '2px solid ' + colors.borderDark,
  radius: '3px',
  boxShadow: '0px 1px 2px #0000000d'
};

const button = {
  background: colors.primary,
  backgroundSecondary: colors.secondary,
  color: colors.white,
  fontSize: '0.9rem',
  textTransform: 'uppercase'
};

const topBar = {
  height: {
    lg: 30,
    md: 30,
    sm: 30,
    scroll: 0
  },
  color: colors.white,
  background: colors.green
};

const desktopHeader = {
  height: {
    lg: 140,
    md: 70,
    scroll: 70
  },
  search: {
    height: {
      lg: 30,
      md: 30
    }
  },
  logo: {
    height: {
      lg: 150,
      md: 50,
      scroll: 50
    }
  },
  nav: {
    fontSize: '1.1rem',
    padding: '0.7rem',
    height: {
      lg: 40,
      md: 40,
      sm: 40,
      scroll: 40
    },
    useSubMenuImage: true
  }
};

const mobileHeader = {
  height: {
    xs: 60,
    sm: 60
  },
  search: {
    height: {
      xs: 60,
      sm: 60
    }
  },
  logo: {
    height: 36
  }
};

const header = {
  height: {
    lg:
      topBar.height.lg + desktopHeader.height.lg + desktopHeader.nav.height.lg,
    md:
      topBar.height.md + desktopHeader.height.md + desktopHeader.nav.height.md,
    sm:
      topBar.height.md + mobileHeader.height.sm + mobileHeader.search.height.sm,
    xs: topBar.height.md + mobileHeader.height.xs,
    scroll: topBar.height.scroll + desktopHeader.height.scroll
  },
  scrollBreak: 50
};

const footer = {
  background: colors.greys[6],
  color: colors.white
};

//CATEGORY PAGE
const categoryPage = {
  subcategories: {
    nameTag: 'h4',
    fontWeight: fontWeights.regular
  },
  filter: {
    markerColor: colors.buy,
    hoverColor: colors.buy
  }
};

const productGrid = {
  imageAspect: '4:3'
};

const productPage = {
  imageAspect: '1:1'
};

export const theme = {
  ...cssMediaQueries,
  fontSizes,
  space,
  fontWeights,
  font,
  colors,
  general,
  button,
  topBar,
  desktopHeader,
  mobileHeader,
  header,
  footer,
  productGrid,
  categoryPage,
  productPage
};

export const ContentPadding = ({ theme }) => css`
  padding: 0.5rem 0 2rem;
  ${theme.above.md} {
    padding: 1rem 0 4rem;
  }
`;
